import { useContext, useEffect, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import configs from "config/base-url";
import { AuthContext } from "providers/AuthProvider";

// Images
import bgImage from "assets/images/wires.png";
import { toast } from "react-toastify";
import { isString } from "utils";
import Footer from "examples/Footer";
import { Grid } from "@mui/material";
import PageLayout from "examples/LayoutContainers/PageLayout";

function Locked() {
  const { logout, user, login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState({
    badge_no: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    password: "",
  });

  const handleInput = (evt) => {
    setError({
      ...error,
      [evt.target.name]: "",
    });
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const unlock = async () => {
    if (!state.password && !state.password.trim().length) {
      setError((prvs) => ({ ...prvs, password: "This field is required" }));
      return;
    }

    try {
      setLoading(true);
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };
      const resp = await axios.post(
        configs.baseURL + "users/login",
        { badge_no: user?.badge_no, password: state.password },
        headers
      );

      const { data } = resp;
      if (data.success) {
        toast.success("Successfully unlocked");
        login(data.response);
        navigate("/wireinsight");
      } else {
        toast.error("Can't unlock, try again");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageLayout>
      <ArgonBox
        minHeight="88vh"
        width="100vw"
        pt={18}
        pb={20}
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            bgImage &&
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Grid container spacing={3} justifyContent="center" sx={{ textAlign: "center" }}>
          <Grid item xs={10} lg={4}>
            <ArgonBox mb={1}>
              <ArgonTypography variant="h1" color="white" fontWeight="bold">
                wireinsight
              </ArgonTypography>
            </ArgonBox>
          </Grid>
        </Grid>

        <ArgonBox mt={3} px={1} width="calc(100% - 2rem)" mx="auto">
          <Grid container justifyContent="center">
            <Grid item xs={11} sm={9} md={5} lg={4} xl={3}>
              <Card sx={{ padding: 3 }}>
                <ArgonBox pt={3} px={3}>
                  <ArgonTypography variant="h5" textAlign="center" color="dark" fontWeight="bold">
                    Locked for: {user?.badge_no}
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox>
                  <ArgonBox component="form" role="form">
                    <ArgonBox mb={3} mt={1}>
                      <ArgonTypography
                        display="block"
                        variant="caption"
                        fontWeight="bold"
                        sx={{ ml: 0.5, mb: 1, textAlign: "center", color: "#adb5bd" }}
                      >
                        Please Input your password to login
                      </ArgonTypography>
                      <ArgonInput
                        type="password"
                        value={state.password}
                        placeholder="Password"
                        name="password"
                        onChange={handleInput}
                        sx={{ mt: 3 }}
                      />
                      {error.password && (
                        <ArgonTypography
                          variant="caption"
                          color="warning"
                          sx={{ ml: 0.5, mt: 0, mb: 0 }}
                        >
                          {error.password}
                        </ArgonTypography>
                      )}
                    </ArgonBox>

                    <ArgonBox mt={3} mb={2}>
                      <ArgonButton
                        onClick={unlock}
                        disabled={loading}
                        sx={{
                          bgcolor: "#000",
                          color: "#fff",
                          "&:hover": { bgcolor: "#000", color: "#fff" },
                          "&:focus:not(:hover)": { bgcolor: "#000", color: "#fff" },
                        }}
                        fullWidth
                      >
                        Login
                      </ArgonButton>
                    </ArgonBox>
                    <ArgonBox mb={3}>
                      <ArgonButton onClick={logout} disabled={loading} color="primary" fullWidth>
                        Change User
                      </ArgonButton>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              </Card>
            </Grid>
          </Grid>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox mx="auto" my={3}>
        <Footer />
      </ArgonBox>
    </PageLayout>
  );
}

export default Locked;
