import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import dayjs from 'dayjs';
import clockIcon from "assets/images/icons/clock.svg";
import cameraIcon from "assets/images/icons/camera.svg";
import { useEffect, useState } from "react";

function ProductInfo({ aiResponse, camera, cameraListData, websocket }) {
  const [currentDateTime, setCurrentDateTime] = useState(dayjs().format("HH:mm | DD/MM/YYYY"));
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDateTime(dayjs().format("HH:mm | DD/MM/YYYY"));
    }, 30 * 1000);
    return () => clearInterval(timer);
  }, []);

  return (
    <ArgonBox style={{ display: "flex", flexDirection: "column", height: "482px" }}>
      <ArgonTypography variant="h6" fontWeight="medium">
        Live Data
      </ArgonTypography>
      <ArgonBox
        mb={1}
        style={{
          flex: "1",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        {aiResponse && (
          <ArgonTypography variant="subtitle1" style={{ marginBottom: "7px" }}>
            Accuracy:{" "}
            <span style={{ fontWeight: "bold" }}>
              {aiResponse?.conf ?? aiResponse?.accuracy ?? 0}%
            </span>
          </ArgonTypography>
        )}
        {aiResponse && (
          <>
            <ArgonBox variant="contained">
              <ArgonTypography
                sx={{
                  bgcolor: aiResponse?.class_id == 0 ? "#B0EED3" : "#FCAEAE",
                  color: aiResponse?.class_id == 0 ? "#1AAE6F" : "#CB3030",
                  fontWeight: "bold",
                  paddingY: 0.5,
                  paddingX: 5,
                  borderRadius: 4,
                  fontSize: 35,
                }}
              >
                {aiResponse?.class_id == 0 ? "PASS/OK" : "NOT OK"}
              </ArgonTypography>
            </ArgonBox>
            {aiResponse?.class_label && (
              <ArgonTypography
                variant="body2"
                sx={{ textAlign: "center", marginTop: 1, maxWidth: "80%", fontSize: 15 }}
              >
                {aiResponse?.class_label}
              </ArgonTypography>
            )}
          </>
        )}
        {!aiResponse && (
          <ArgonBox
            sx={(theme) => {
              return {
                padding: "8px 12px",
              };
            }}
          >
            <ArgonTypography fontSize={40} fontWeight={700}  color="dark" textAlign='center' sx>
              {websocket?.readyState == 1 || websocket?.readyState == 2
                ? "Camera Streaming"
                : "No Camera Streaming.."}
            </ArgonTypography>
          </ArgonBox>
        )}
      </ArgonBox>

      <ArgonBox mt={3} style={{ display: "flex", justifyContent: "space-between" }}>
        <ArgonBox display="flex" alignItems="center" sx={{ gap: 1 }}>
          <ArgonBox component="img" src={clockIcon} alt="time" width="23px" height="23px" />
          <p style={{ fontSize: 16, fontWeight: "bolder" }}>{currentDateTime}</p>
        </ArgonBox>
        <ArgonBox display="flex" alignItems="center" sx={{ gap: 1 }}>
          <ArgonBox component="img" src={cameraIcon} alt="camera" width="23px" height="23px" />
          <p style={{ fontSize: 16, fontWeight: "bolder", color: "blue" }}>
            {camera
              ? `${camera.camera_universal_id} |${camera.camera_ip_address}:${camera.port}`
              : cameraListData && cameraListData.length > 0
              ? cameraListData[0].camera_universal_id
              : 0}
          </p>
        </ArgonBox>
      </ArgonBox>
    </ArgonBox>
  );
}

export default ProductInfo;
