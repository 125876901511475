import configs from "config/base-url";

export const getLineGraphData = (data) => {
  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const total = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const nok = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
  const ok = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  data?.forEach((ele) => {
    const pos = labels.findIndex((mon) => mon == ele.month.slice(0, 3));
    total[pos] = ele.count;
    nok[pos] = ele.nok;
    ok[pos] = ele.ok;
  });

  return {
    labels,
    datasets: [
      {
        label: "Total",
        color: "info",
        data: total,
      },
      {
        label: "Not Ok",
        color: "dark",
        data: nok,
      },
      {
        label: "Ok",
        color: "success",
        data: ok,
      },
    ],
  };
};

export const getChannelsChartData = (data) => {
  return {
    labels: configs.CaseOptions.map((ele) => ele.label) || [],
    datasets: {
      label: "Projects",
      backgroundColors: configs.CaseOptions.map((ele) => ele.color) || [],
      data: data?.map((ele) => ele.count) || [],
    },
  };
};
export const getBarchartData = (data, classes = []) => {
  const cData = {
    labels: [],
    datasets: [
      {
        backgroundColor: [],
        color: "caseColorFour",
        data: [],
        maxBarThickness: 40,
      },
    ],
  };

  classes.forEach(async (cls) => {
    const finalFilter = data.find(ele => {
      if (cls == ele.class) {
        cData.labels.push(`Class ${cls}`);
        cData.datasets[0].backgroundColor.push(configs.CaseOptions.find(val => val.value == cls).color)
        return ele;
      }
    })?.count;
    if (finalFilter) {
      cData.datasets[0].data.push(finalFilter)
    }
  });
  return cData;
};
