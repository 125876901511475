import { createRef, useContext, useEffect, useMemo, useRef, useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import ArgonButton from "components/ArgonButton";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import DataTable from "examples/Tables/DataTable";

// Overview page components
import ChannelsChart from "layouts/ecommerce/overview/components/ChannelsChart";

import { usePDF, Margin } from "react-to-pdf";
import { AuthContext } from "providers/AuthProvider";
import axios from "axios";
import configs from "config/base-url";
import { getBarchartData, getChannelsChartData, getLineGraphData } from "utils/chartData";
import RangePicker from "components/RangePicker";
import dayjs from "dayjs";
import { toast } from "react-toastify";
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import CustomerCell from "../orders/order-list/components/CustomerCell";
import DefaultCell from "./components/DefaultCell";
import historyImg from "assets/images/wire-bg.jpg";
import DeleteIcon from "@mui/icons-material/Delete";
import editIcon from "assets/images/icons/Edit.svg";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ArgonInput from "components/ArgonInput";
import { isString } from "utils";
import ConfirmationModal from "components/ConfrimationModal";
import imgPlaceholder from "assets/images/img-placeholder.png";
import ArgonDatePicker from "components/ArgonDatePicker";
import WireClassSelect from "components/WireClassSelect";
import ImageIndicator from "components/ImageIndicator";

function Overview() {
  const { toPDF, targetRef } = usePDF({
    filename: "dashboard.pdf",
    page: { margin: Margin.MEDIUM },
  });

  const { user } = useContext(AuthContext);

  // DefaultStatisticsCard state for the dropdown value
  const [salesDropdownValue, setSalesDropdownValue] = useState(dayjs().format("DD[th] MMMM"));
  const [customersDropdownValue, setCustomersDropdownValue] = useState(
    dayjs().format("DD[th] MMMM")
  );
  const [revenueDropdownValue, setRevenueDropdownValue] = useState(dayjs().format("DD[th] MMMM"));

  // DefaultStatisticsCard state for the dropdown action
  const [salesDropdown, setSalesDropdown] = useState(null);
  const [customersDropdown, setCustomersDropdown] = useState(null);
  const [revenueDropdown, setRevenueDropdown] = useState(null);

  const [openFilter, setOpenFilter] = useState(false);
  const [filterValue, setFilterValue] = useState({ from: "", to: "" });
  const [singleDay, setSingleDay] = useState("");
  const [channelListData, setChannelListData] = useState(null);

  // DefaultStatisticsCard handler for the dropdown action
  const openSalesDropdown = ({ currentTarget }) => setSalesDropdown(currentTarget);
  const closeSalesDropdown = ({ currentTarget }) => {
    setSalesDropdown(null);
    setSalesDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openCustomersDropdown = ({ currentTarget }) => setCustomersDropdown(currentTarget);
  const closeCustomersDropdown = ({ currentTarget }) => {
    setCustomersDropdown(null);
    setCustomersDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const openRevenueDropdown = ({ currentTarget }) => setRevenueDropdown(currentTarget);
  const closeRevenueDropdown = ({ currentTarget }) => {
    setRevenueDropdown(null);
    setRevenueDropdownValue(currentTarget.innerText || salesDropdownValue);
  };
  const [dashboardData, setDashboardData] = useState({});

  const lineChartData = useMemo(
    () => getLineGraphData(dashboardData?.monthWiseData || []),
    [dashboardData]
  );

  const sinceLastMonth = useMemo(
    () => {
      const data = dashboardData?.monthWiseData || [];
      const currentMonthData = data.find(item => item.month === dayjs().format('MMMM'))
      const lastMonthData = data.find(item => item.month === dayjs().subtract(1, 'month').format('MMMM'))
      if (currentMonthData && lastMonthData) {
        const currentTotal = currentMonthData.ok + currentMonthData.nok + lastMonthData.ok + lastMonthData.nok
        const lastTotal = currentMonthData.ok + currentMonthData.nok

        const total = (currentTotal / lastTotal) * 100;
        const ok = (currentMonthData.ok / lastMonthData.ok) * 100;
        const nok = (currentMonthData.nok / lastMonthData.nok) * 100;
        return { total, ok, nok }
      }

      if (currentMonthData) {
        return { total: 100, ok: 100, nok: 100 }
      }

      return { total: 0, ok: 0, nok: 0 }
    },
    [dashboardData]
  );

  const channelsData = useMemo(
    () => getChannelsChartData(channelListData || []),
    [channelListData]
  );

  const horizontalBarChartData = useMemo(() => getBarchartData(dashboardData?.classWiseNokData || [], [1, 4, 6, 8]), [dashboardData]);

  const [openViewBox, setOpenViewBox] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    message: "",
    data: "",
  });

  const [tableDataState, setTableDataState] = useState({
    columns: [
      {
        Header: "Image Scanned",
        accessor: "id",
        Cell: ({ value, row }) => (
          <ArgonBox display="flex" alignItems="center">
            <Checkbox defaultChecked={false} />
            <CustomerCell
              name={value.slice(0, 10).toUpperCase()}
              image={
                row.original?.image &&
                `data:image/${Buffer.from(row.original?.image).toString("base64").charAt(0) == "/"
                  ? "jpg"
                  : "png"
                };base64,${Buffer.from(row.original?.image).toString("base64")}`
              }
            />
          </ArgonBox>
        ),
      },
      {
        Header: "Date & Time",
        accessor: "createdAt",
        Cell: ({ value }) => <DefaultCell>{dayjs(value).format("DD.MM.YYYY HH:mm")}</DefaultCell>,
      },
      {
        Header: "IP Camera",
        accessor: "camera_id",
        Cell: ({ value, row }) => <DefaultCell>{row?.original?.camera_id != "0" ? `${row.original.camera.camera_ip_address}:${row.original.camera.port}` : "image upload"}</DefaultCell>
      },
      {
        Header: "Employee",
        accessor: "user",
        Cell: ({ value }) => <DefaultCell>{value?.badge_no}</DefaultCell>,
      },
      {
        Header: "Workplace ID",
        accessor: "camera.workplace",
        Cell: ({ value, row }) => <DefaultCell>{row?.original?.camera_id != "0" ? `${row.original.camera.workplace_id.slice(0, 10)}` : "NA"}</DefaultCell>,
      },
      {
        Header: "STATUS",
        accessor: "status",
        Cell: ({ value, row }) => (
          <ArgonBox
            sx={{
              bgcolor: row?.original?.wire_class == '0' ? "#B0EED3" : "#FDD1DA",
              color: row?.original?.wire_class == '0' ? "#1AAE6F" : "#F80031",
              fontWeight: "bold",
              paddingX: 1,
              borderRadius: 1,
              fontSize: 12,
            }}
          >
            {row?.original?.wire_class == '0' ? "OK/PASS" : "NOT OK"}
          </ArgonBox>
        ),
      },
      {
        Header: "ACTIONS",
        // accessor: "wire_class",
        Cell: ({ row }) => (
          <ArgonBox display="flex" alignItems="center" sx={{ gap: 2 }}>
            <ArgonBox onClick={() => handleViewClick(row)}>
              <Icon fontSize="small" color="secondary">
                <VisibilityIcon />
              </Icon>
            </ArgonBox>
            <ArgonBox onClick={() => handleEditClick(row)}>
              <img style={{ width: 50 }} src={editIcon} />
            </ArgonBox>
            <ArgonBox
              onClick={() => {
                setDeleteConfirmation({
                  open: true,
                  data: row.original.id,
                  message: `Are you sure you want to delete this history`,
                });
              }}
            >
              <Icon fontSize="small" color="secondary">
                <DeleteIcon />
              </Icon>
            </ArgonBox>
          </ArgonBox>
        ),
      },
    ],

    rows: [],
  });

  const handleDeleteSubmit = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };

      const resp = await axios.delete(
        configs.baseURL + "troubleshoot/delete-troubleshoot/" + deleteConfirmation.data,
        {
          headers,
        }
      );

      const { data } = resp;
      if (data.success) {
        setDeleteConfirmation({ open: false, message: "", data: "" });
        fetchTroubleShootHistory();
        toast.success("Troubleshoot history deleted!");
      } else {
        toast.error("Can't delete this history, try again");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong, try again");
    }
  };

  const fetchDashboardData = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };

      let dRange = { ...filterValue };
      if (singleDay) {
        dRange.from = new Date(dayjs(singleDay).startOf("day"));
        dRange.to = new Date(dayjs(singleDay).endOf("day"));
      }

      const resp = await axios.get(configs.baseURL + "wire-insight/dashboard-data", {
        headers,
        params: {
          start: dRange.from,
          end: dRange.to,
        },
      });

      const { data } = resp;
      if (data.success) {
        setDashboardData(data.response);
      } else {
        toast.error("Can't fetch dashboard data");
      }
    } catch (error) {
      toast.error("Can't fetch dashboard data");
    }
  };

  const fetchTroubleShootHistory = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };

      let dRange = { ...filterValue };
      if (singleDay) {
        dRange.from = new Date(dayjs(singleDay).startOf("day"));
        dRange.to = new Date(dayjs(singleDay).endOf("day"));
      }

      const resp = await axios.get(configs.baseURL + "troubleshoot/get-all-troubleshoot", {
        headers,
        params: {
          dateRange: dRange,
        },
      });

      const { data } = resp;
      if (data.success) {
        setTableDataState({
          ...tableDataState,
          rows: data.response.data,
        });
      } else {
        toast.error("Can't fetch troubleshoot histroy");
      }
    } catch (err) {
      toast.error("Can't fetch troubleshoot histroy");
    }
  };

  useEffect(() => {
    fetchDashboardData();
    fetchTroubleShootHistory();
  }, []);

  useEffect(() => {
    if (dashboardData) {
      let dataArray = [
        { class: "0", count: 0 },
        { class: "1", count: 0 },
        { class: "4", count: 0 },
        { class: "6", count: 0 },
        { class: "8", count: 0 },
      ]
      if (dashboardData?.classWiseConfirmityData) {
        for (let i = 0; i < dataArray.length; i++) {
          const element = dataArray[i];
          const newObj = dashboardData?.classWiseConfirmityData.find(ob => ob.class == element.class)

          dataArray[i].count = newObj ? newObj.count : 0
        }

        setChannelListData(dataArray)
      }
    }
  }, [dashboardData])

  const [editData, setEditData] = useState({
    id: "",
    status: "",
    accuracy: "",
    wire_class: "",
    coordinates: {},
    issue_type: "",
    comments: "",
  });
  const [loadingEdit, setLoadingEdit] = useState(false);
  const [openEditBox, setOpenEditBox] = useState(false);

  const handleEditClick = (row) => {
    const { original } = row;
    setEditData({
      id: original.id,
      status: original.status,
      accuracy: original.accuracy,
      wire_class: original.wire_class.toString(),
      coordinates: original.coordinates,
      issue_type: original.issue_type,
      comments: original.comments,
    });
    setOpenEditBox(true);
  };

  const handleEditForm = (evt) => {
    if (evt.target.checked) {
      setEditData((prvs) => ({
        ...prvs,
        [evt.target.name]: evt.target.value == "true",
      }));
    } else if (evt.target.name.split(".").length > 1) {
      const field = evt.target.name.split(".");
      setEditData((prvs) => ({
        ...prvs,
        coordinates: {
          ...prvs.coordinates,
          [field[1]]: evt.target.value,
        },
      }));
    } else {
      setEditData((prvs) => ({
        ...prvs,
        [evt.target.name]: evt.target.value.toString(),
      }));
    }
  };

  const submitEditForm = async () => {
    setLoadingEdit(true);
    try {
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };
      const { id, ...newData } = editData;
      const resp = await axios.put(
        configs.baseURL + "troubleshoot/edit-troubleshoot/" + id,
        newData,
        {
          headers,
        }
      );
      const { data } = resp;
      if (data.success) {
        fetchTroubleShootHistory();
        toast.success("History data updated");
        setOpenEditBox(false);
      } else {
        toast.error("Can't save data!, try again");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    } finally {
      setLoadingEdit(false);
    }
  };

  const [viewData, setViewData] = useState({
    id: "",
  });

  const handleViewClick = ({ original }) => {
    setViewData(original);
    setOpenViewBox(true);
  };

  // Dropdown menu template for the DefaultStatisticsCard
  const renderMenu = (state, close) => (
    <Menu
      anchorEl={state}
      transformOrigin={{ vertical: "top", horizontal: "center" }}
      open={Boolean(state)}
      onClose={close}
      keepMounted
      disableAutoFocusItem
    >
      <MenuItem onClick={close}>Last 7 days</MenuItem>
      <MenuItem onClick={close}>Last week</MenuItem>
      <MenuItem onClick={close}>Last 30 days</MenuItem>
    </Menu>
  );

  useEffect(() => {
    if (filterValue.to || filterValue.from) setSingleDay("");
  }, [filterValue]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox
        display="flex"
        alignItems={{ md: "center" }}
        justifyContent="space-between"
        flexDirection={{ md: "row", xs: "column" }}
        sx={{ gap: 2 }}
        mt={5}
      >
        <ArgonBox display="flex" flexDirection="column" sx={{ zIndex: 10 }}>
          <ArgonTypography style={{ color: "white" }} variant="h4">
            Dashboard
          </ArgonTypography>
          <ArgonTypography style={{ color: "white" }} variant="body2">
            All the KPIs you need to measure how things are going!
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox
          display="flex"
          justifyContent="space-between"
          alignSelf={{ xs: "center", sm: "flex-end", md: "center" }}
          sx={{ gap: 4 }}
        >
          <ArgonButton
            sx={{ color: "primary", paddingLeft: 5, paddingRight: 5 }}
            variant="contained"
            onClick={() => {
              setOpenFilter(true);
            }}
          >
            Filter
          </ArgonButton>
          {user?.role == "manager" && (
            <ArgonButton
              variant="outlined"
              sx={{
                paddingLeft: 5,
                paddingRight: 5,
                boxShadow: { xs: 4, sm: 0 },
                bgcolor: { xs: "#5e72e4" },
              }}
              onClick={toPDF}
            >
              Export
            </ArgonButton>
          )}
        </ArgonBox>
      </ArgonBox>
      <div ref={targetRef}>
        <ArgonBox py={3} mt={2}>
          <ArgonBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <DefaultStatisticsCard
                  title="Total Cases"
                  count={dashboardData.total ?? "00"}
                  percentage={{
                    color: "success",
                    value: `${sinceLastMonth.total.toFixed(2)}%`,
                    label: "since last month",
                  }}
                  dropdown={{
                    action: openSalesDropdown,
                    menu: renderMenu(salesDropdown, closeSalesDropdown),
                    value: salesDropdownValue,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DefaultStatisticsCard
                  title="Ok Cases"
                  count={dashboardData.okCount ?? "00"}
                  percentage={{
                    color: "success",
                    value: `${sinceLastMonth.ok.toFixed(2)}%`,
                    label: "since last month",
                  }}
                  dropdown={{
                    action: openCustomersDropdown,
                    menu: renderMenu(customersDropdown, closeCustomersDropdown),
                    value: customersDropdownValue,
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DefaultStatisticsCard
                  title="NOK Cases"
                  count={dashboardData.nokCount ?? "00"}
                  percentage={{
                    color: "secondary",
                    value: `${sinceLastMonth.nok.toFixed(2)}%`,
                    label: "since last month",
                  }}
                  dropdown={{
                    action: openRevenueDropdown,
                    menu: renderMenu(revenueDropdown, closeRevenueDropdown),
                    value: revenueDropdownValue,
                  }}
                />
              </Grid>
            </Grid>
          </ArgonBox>
          <ArgonBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={4}>
                <ChannelsChart data={channelsData} />
              </Grid>
              <Grid item xs={12} sm={6} lg={8}>
                <DefaultLineChart
                  title="Cases Overtime Evolution"
                  description={
                    <ArgonBox display="flex" justifyContent="space-between">
                      <ArgonBox display="flex" ml={-1}>
                        <ArgonBadgeDot color="info" size="sm" badgeContent="Total" />
                        <ArgonBadgeDot color="dark" size="sm" badgeContent="Not Ok" />
                        <ArgonBadgeDot color="success" size="sm" badgeContent="Ok" />
                      </ArgonBox>
                      <ArgonBox mt={-5.25} mr={-1}>
                        <Tooltip title="See evaluation result over time" placement="left" arrow>
                          <ArgonButton
                            variant="outlined"
                            color="secondary"
                            size="small"
                            circular
                            iconOnly
                          >
                            <Icon>priority_high</Icon>
                          </ArgonButton>
                        </Tooltip>
                      </ArgonBox>
                    </ArgonBox>
                  }
                  chart={lineChartData}
                />
              </Grid>
            </Grid>
          </ArgonBox>
          <ArgonBox mb={3}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={12}>
                <HorizontalBarChart title="Non conformities" data={horizontalBarChartData} />
                {/* <Bar height={270} options={options} data={horizontalBarChartData} /> */}
              </Grid>
            </Grid>
          </ArgonBox>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Card>
                <ArgonBox pt={3} px={3}>
                  <ArgonTypography variant="h6" fontWeight="medium">
                    Troubleshooted Cases
                  </ArgonTypography>
                </ArgonBox>
                <ArgonBox py={1}>
                  {tableDataState.rows.length > 0 && (
                    <DataTable table={tableDataState} entriesPerPage={false} />
                  )}
                </ArgonBox>
              </Card>
            </Grid>
          </Grid>
        </ArgonBox>
      </div>

      {/* filter */}
      <Dialog
        close={() => {
          setMenu(false);
        }}
        open={openFilter}
      >
        <Card sx={{ p: 2 }}>
          <DialogTitle id="alert-dialog-title">Filter Data</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              width="100%"
              mt={2}
            >
              <ArgonTypography variant="caption" mb={1}>
                Pick Date Range
              </ArgonTypography>
              <RangePicker value={filterValue} onChange={setFilterValue} />
            </ArgonBox>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              width="100%"
              mt={2}
            >
              <ArgonTypography variant="caption" mb={1}>
                Pick a day
              </ArgonTypography>
              <ArgonDatePicker
                input={{ placeholder: "YYYY-MM-DD" }}
                value={singleDay}
                onChange={(cValue) => {
                  setFilterValue({ from: "", to: "" });
                  setSingleDay(cValue);
                }}
              />
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonButton
              sx={{ mr: 1 }}
              color="warning"
              variant="text"
              onClick={() => {
                setOpenFilter(false);
              }}
            >
              Cancel
            </ArgonButton>

            <ArgonBox>
              <ArgonButton
                color="primary"
                fullWidth
                onClick={() => {
                  fetchDashboardData();
                  fetchTroubleShootHistory();
                  setOpenFilter(false);
                }}
              >
                Filter
              </ArgonButton>
            </ArgonBox>
          </DialogActions>
        </Card>
      </Dialog>

      {/* edit dialog  */}
      <Dialog open={openEditBox} onClose={() => { }}>
        <Card sx={{ p: 2 }}>
          <DialogTitle id="alert-dialog-title">{"Edit History"}</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <ArgonBox mt={1.625}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ArgonTypography variant="caption" mb={1}>
                    Status
                  </ArgonTypography>
                  <RadioGroup
                    sx={{ display: "flex", ml: 1, flexDirection: "row", gap: 1 }}
                    name="status"
                    value={editData.status}
                    onChange={handleEditForm}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="OK" />
                    <FormControlLabel value={false} control={<Radio />} label="NOT Ok" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ArgonTypography variant="caption" mb={1}>
                    Accuracy
                  </ArgonTypography>
                  <ArgonInput
                    label="Accuracy"
                    value={editData.accuracy}
                    type="text"
                    placeholder="Accuracy"
                    name="accuracy"
                    onChange={handleEditForm}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <ArgonTypography variant="caption" mb={1}>
                    Wire Class
                  </ArgonTypography>
                  <WireClassSelect
                    label="Wire Class"
                    placeholder="Wire Class"
                    name="wire_class"
                    value={editData.wire_class}
                    onChange={handleEditForm}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <ArgonTypography variant="caption" mb={1}>
                    Issue Type
                  </ArgonTypography>
                  <ArgonInput
                    label="Issue Type"
                    type="text"
                    placeholder="Issue Type"
                    name="issue_type"
                    value={editData.issue_type}
                    onChange={handleEditForm}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="caption" mb={1}>
                    Comments
                  </ArgonTypography>
                  <ArgonInput
                    label="Comments"
                    type="text"
                    placeholder="Comments"
                    name="comments"
                    value={editData.comments}
                    onChange={handleEditForm}
                    multiline
                    rows={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="body" mb={1}>
                    Coordinates
                  </ArgonTypography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    X min
                  </ArgonTypography>
                  <ArgonInput
                    label="X min"
                    type="text"
                    placeholder="X min"
                    name="coordinates.x_min"
                    value={editData.coordinates?.x_min}
                    onChange={handleEditForm}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    Y min
                  </ArgonTypography>
                  <ArgonInput
                    label="Y min"
                    type="text"
                    placeholder="Y min"
                    name="coordinates.y_min"
                    value={editData.coordinates?.y_min}
                    onChange={handleEditForm}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    X max
                  </ArgonTypography>
                  <ArgonInput
                    label="X max"
                    type="text"
                    placeholder="X max"
                    name="coordinates.x_max"
                    value={editData.coordinates?.x_max}
                    onChange={handleEditForm}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    Y max
                  </ArgonTypography>
                  <ArgonInput
                    label="Y max"
                    type="text"
                    placeholder="Y max"
                    name="coordinates.y_max"
                    value={editData.coordinates?.y_max}
                    onChange={handleEditForm}
                  />
                </Grid>
              </Grid>
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonButton
              sx={{ mr: 1 }}
              color="warning"
              variant="text"
              disabled={loadingEdit}
              onClick={() => {
                setOpenEditBox(false);
              }}
            >
              Cancel
            </ArgonButton>

            <ArgonBox>
              <ArgonButton
                onClick={submitEditForm}
                disabled={loadingEdit}
                color="primary"
                fullWidth
              >
                Submit
              </ArgonButton>
            </ArgonBox>
          </DialogActions>
        </Card>
      </Dialog>

      {/* view dialog  */}
      <Dialog
        open={openViewBox}
        onClose={() => {
          setOpenViewBox(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <Card sx={{ p: 2 }}>
          <DialogTitle id="alert-dialog-title">{viewData?.id}</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <ArgonBox mt={1.625}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ArgonBox
                    sx={{
                      width: "-webkit-fill-available",
                      borderRadius: 10,
                      backgroundColor: "#000",
                      height: '400px'
                    }}>
                    <ImageIndicator
                      class_id={viewData?.wire_class}
                      image={viewData?.image || imgPlaceholder}
                      bbox={viewData.coordinates ? [viewData.coordinates?.x_min || 0, viewData.coordinates?.y_min || 0, viewData.coordinates?.x_max || 0, viewData.coordinates?.y_max || 0] : undefined}
                    />
                  </ArgonBox>
                </Grid>
                <Grid item xs={6}>
                  <ArgonTypography variant="caption" mb={1}>
                    Accuracy
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonTypography variant="body">{viewData.accuracy}%</ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={6}>
                  <ArgonTypography variant="caption" mb={1}>
                    Status
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonBox
                      sx={{
                        bgcolor: viewData.wire_class == '0' ? "#B0EED3" : "#FDD1DA",
                        color: viewData.wire_class == '0' ? "#1AAE6F" : "#F80031",
                        fontWeight: "bold",
                        paddingX: 1,
                        borderRadius: 1,
                        fontSize: 12,
                      }}
                    >
                      {viewData.wire_class == '0' ? "OK/PASS" : "NOT OK"}
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="body">Wire class</ArgonTypography>
                </Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="body"> {viewData.wire_class && configs.CaseOptions.find(val => viewData.wire_class == val.value).label} </ArgonTypography>
                </Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="body">Coordinates</ArgonTypography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    X min
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonTypography variant="body">
                      {viewData.coordinates?.x_min ?? "-"}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    Y min
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonTypography variant="body">
                      {viewData.coordinates?.y_min ?? "-"}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    X max
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonTypography variant="body">
                      {viewData.coordinates?.x_max ?? "-"}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    Y max
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonTypography variant="body">
                      {viewData.coordinates?.y_max ?? "-"}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="caption" mb={1}>
                    Issue Type
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonTypography variant="body">{viewData.issue_type}</ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="caption" mb={1}>
                    Comments
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonTypography variant="body">{viewData.comments}</ArgonTypography>
                  </ArgonBox>
                </Grid>
              </Grid>
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonBox>
              <ArgonButton
                color="primary"
                fullWidth
                onClick={() => {
                  setOpenViewBox(false);
                }}
              >
                Close
              </ArgonButton>
            </ArgonBox>
          </DialogActions>
        </Card>
      </Dialog>
      <ConfirmationModal
        open={deleteConfirmation.open}
        onClose={() => {
          setDeleteConfirmation({ open: false, data: "", message: "" });
        }}
        onConfirm={handleDeleteSubmit}
        message={deleteConfirmation.message}
      />
    </DashboardLayout>
  );
}

export default Overview;
