import Card from "@mui/material/Card";
import { Box, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCamera, faBriefcase, faEdit, faTrash } from "@fortawesome/free-solid-svg-icons";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Data
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import ArgonSelect from "components/ArgonSelect";
import ArgonInput from "components/ArgonInput";
import { Link } from "react-router-dom";
import axios from "axios";
import configs from "config/base-url";
import { toast } from "react-toastify";
import { isString } from "utils";
import ConfirmationModal from "components/ConfrimationModal";
import Cam from "assets/images/cam.svg";

const initialState = {
  id: "",
  workplace_id: "",
  camera_universal_id: "",
  camera_ip_address: "",
  port: "",
};

function ConfigureCamera({ cameras, refetch, workplaces }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setState(initialState);
  };

  const [state, setState] = useState(initialState);

  const [workplaceList, setWorkplaceList] = useState([]);

  const [tableDataState, setTableDataState] = useState({
    columns: [
      { Header: "Universal_ID", accessor: "camera_universal_id", width: "100%" },
      { Header: "IP", accessor: "camera_ip_address", width: "100%" },
      { Header: "Port", accessor: "port", width: "100%" },
      { Header: "Action", accessor: "action" },
    ],

    rows: [],
  });

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    message: "",
    data: "",
  });

  useEffect(() => {
    setTableDataState({
      ...tableDataState,
      rows: cameras.map((obj, index) => ({
        ...obj,
        action: (
          <ArgonBox display="flex" alignItems="center" sx={{ gap: 2 }}>
            <FontAwesomeIcon
              icon={faEdit}
              color="black"
              fontSize={"1rem"}
              onClick={() => handleEdit(obj)}
            />
            <FontAwesomeIcon
              icon={faTrash}
              color="black"
              fontSize={"1rem"}
              onClick={() => {
                setDeleteConfirmation({
                  open: true,
                  data: obj.id,
                  message: `Are you sure you want to delete ${obj.camera_ip_address}`,
                });
              }}
            />
          </ArgonBox>
        ),
      })),
    });
  }, [cameras]);

  useEffect(() => {
    setWorkplaceList(
      workplaces.map((obj) => ({
        value: obj.id,
        label: obj.workplace_name,
      }))
    );
  }, [workplaces]);

  const handleEdit = async (obj) => {
    const { id, workplace_id, camera_universal_id, camera_ip_address, port } = obj;
    setState({
      id,
      workplace_id,
      camera_universal_id,
      camera_ip_address,
      port,
    });

    handleOpen();
  };

  const handleForm = (evt) => {
    if (evt.value) {
      setState({
        ...state,
        workplace_id: evt.value,
      });
    } else {
      const value = evt.target.value;
      setState({
        ...state,
        [evt.target.name]: value,
      });
    }
  };

  const [loadingMutation, setLoadingMutation] = useState(false);

  const handleSubmit = async () => {
    setLoadingMutation(true);
    try {
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };
      const { id, ...cameraData } = state;
      const resp = await axios.post(configs.baseURL + "camera/add-camera", cameraData, { headers });

      const { data } = resp;
      if (data.success) {
        refetch();
        toast.success("New camera added!");
        handleClose();
      } else {
        toast.error("Can't save data, try again");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    } finally {
      setLoadingMutation(false);
    }
  };

  const handleEditSubmit = async () => {
    setLoadingMutation(true);
    try {
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };
      const { id, ...cameraData } = state;
      const resp = await axios.put(configs.baseURL + "camera/edit-camera/" + state.id, cameraData, {
        headers,
      });

      const { data } = resp;
      if (data.success) {
        refetch();
        toast.success("Camera details updated!");
        handleClose();
      } else {
        toast.error("Can't save data, try again");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    } finally {
      setLoadingMutation(false);
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };

      const resp = await axios.delete(configs.baseURL + "camera/delete-camera/" + deleteConfirmation.data, {
        headers,
      });

      const { data } = resp;
      if (data.success) {
        setDeleteConfirmation({ open: false, message: "", data: "" });
        refetch();
        toast.success("Camera deleted!");
      } else {
        toast.error("Can't delete camera, try again");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    }
  };

  return (
    <Card sx={{ overflow: "visible" }}>
      <ArgonBox display="flex" justifyContent="center" alignItems="center" pt={2} px={2}>
        <ArgonTypography variant="h6">Camera Configuration</ArgonTypography>
      </ArgonBox>
      <ArgonBox
        display="flex"
        justifyContent="center"
        height={60}
        alignItems="flex-start"
        pt={1}
        px={2}
      >
        <p style={{ fontSize: "15px", textAlign: "center" }}>
          You can add a camera or edit its IP address or details
        </p>
      </ArgonBox>
      <ArgonBox p={2} mt={3}>
        <Grid container alignItems="center" justifyContent={"center"}>
          <Grid item xs={12} textAlign={"center"}>
            <img src={Cam} height={150} width={150} />
            {/* <FontAwesomeIcon icon={faCamera} color="black" fontSize={"6rem"} /> */}
          </Grid>
        </Grid>
      </ArgonBox>
      <ArgonBox
        pt={4}
        pb={2}
        px={2}
        display="flex"
        justifyContent="center"
        flexDirection={{ xs: "column", sm: "row" }}
        mt="auto"
      >
        <ArgonButton color="primary" onClick={handleOpen}>
          Add
        </ArgonButton>
      </ArgonBox>
      <ArgonBox py={1}>
        <DataTable
          table={tableDataState}
          entriesPerPage={false}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
        />
      </ArgonBox>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth
      >
        <Card sx={{ p: 2 }}>
          <DialogTitle>{state.id ? "Edit" : "Add"} Camera</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <ArgonBox p={2}>
              <ArgonBox>
                <ArgonBox>
                  <ArgonBox mt={1.625}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={12}>
                        <ArgonBox
                          display="flex"
                          flexDirection="column"
                          justifyContent="flex-end"
                          height="100%"
                        >
                          <ArgonTypography variant="caption" mb={1}>
                            Workplace
                          </ArgonTypography>
                          <ArgonSelect
                            defaultValue={workplaceList.find(
                              (obj) => obj.value === state.workplace_id
                            )}
                            name="workplace_id"
                            placeholder="Workplace"
                            options={workplaceList}
                            onChange={handleForm}
                            isSearchable
                            isClearable
                          />
                        </ArgonBox>
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ArgonTypography variant="caption" mb={1}>
                          Camera Universal ID
                        </ArgonTypography>
                        <ArgonInput
                          label="name"
                          type="text"
                          value={state.camera_universal_id}
                          placeholder="Camera Universal ID"
                          name="camera_universal_id"
                          onChange={handleForm}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ArgonTypography variant="caption" mb={1}>
                          Camera IP Address
                        </ArgonTypography>
                        <ArgonInput
                          label="name"
                          type="text"
                          value={state.camera_ip_address}
                          placeholder="Camera IP Address"
                          name="camera_ip_address"
                          onChange={handleForm}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <ArgonTypography variant="caption" mb={1}>
                          Port
                        </ArgonTypography>
                        <ArgonInput
                          type="text"
                          value={state.port}
                          placeholder="Port"
                          name="port"
                          onChange={handleForm}
                        />
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonButton
              sx={{ mr: 1 }}
              color="warning"
              variant="text"
              onClick={() => {
                setState(initialState);
                handleClose();
              }}
            >
              Cancel
            </ArgonButton>
            {state?.id ? (
              <ArgonBox>
                <Link onClick={handleEditSubmit}>
                  <ArgonButton disabled={loadingMutation} color="primary" fullWidth>
                    Update
                  </ArgonButton>
                </Link>
              </ArgonBox>
            ) : (
              <ArgonBox>
                <Link onClick={handleSubmit}>
                  <ArgonButton disabled={loadingMutation} color="primary" fullWidth>
                    Submit
                  </ArgonButton>
                </Link>
              </ArgonBox>
            )}
          </DialogActions>
        </Card>
      </Dialog>
      <ConfirmationModal
        open={deleteConfirmation.open}
        onClose={() => {
          setDeleteConfirmation({ open: false, data: "", message: "" });
        }}
        onConfirm={handleDeleteSubmit}
        message={deleteConfirmation.message}
      />
    </Card>
  );
}

export default ConfigureCamera;
