 

// @mui material components
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";
import ArgonBadgeDot from "components/ArgonBadgeDot";
import PieChart from "examples/Charts/PieChart";
import configData from "config/base-url";

function ChannelsChart({ data }) {
  return (
    <Card sx={{ overflow: "visible" }}>
      <ArgonBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
        <ArgonTypography variant="h6">OK & NOK Cases</ArgonTypography>
        <Tooltip title="See traffic channels" placement="bottom" arrow>
          <ArgonButton variant="outlined" color="secondary" size="small" circular iconOnly>
            <Icon>priority_high</Icon>
          </ArgonButton>
        </Tooltip>
      </ArgonBox>
      <ArgonBox p={2} mt={3}>
        <Grid container alignItems="center">
          <Grid item xs={7}>
            <PieChart chart={data} height="172px" />
          </Grid>
          <Grid item xs={5}>
            <ArgonBox px={1}>
              {configData.CaseOptions
                .map((ele) => (
                  <ArgonBox key={ele} mb={0.5}>
                    <ArgonBadgeDot
                      color={ele.color}
                      size="sm"
                      badgeContent={`${ele.label}`}
                    />
                  </ArgonBox>
                ))}
            </ArgonBox>
          </Grid>
        </Grid>
      </ArgonBox>
      <ArgonBox
        pt={4}
        pb={2}
        px={2}
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        mt="auto"
      >
        <ArgonBox width={{ xs: "100%", sm: "60%" }} lineHeight={1}>
          <ArgonTypography variant="button" color="text" fontWeight="regular">
            More than <strong>100</strong> images are passed with an average accuracy with
            99.14%. The most occuring default is excessive welding representing 67% of Non-OK cases.
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
    </Card>
  );
}

export default ChannelsChart;
