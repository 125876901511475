import {
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import ArgonButton from "components/ArgonButton";
import React from "react";

const ConfirmationModal = ({ open, onClose, onConfirm, message }) => {
  return (
    <Dialog open={open} onClose={() => {}} fullWidth maxWidth="sm">
      <Card sx={{ padding: 2 }}>
        <DialogTitle id="alert-dialog-title">You&apos;re deleting this data!</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {message ? message : "Are you sure you want to delete this data"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <ArgonButton color="primary" variant="contained" onClick={onClose}>
            Cancel
          </ArgonButton>
          <ArgonButton color="warning" onClick={onConfirm} autoFocus>
            Confirm
          </ArgonButton>
        </DialogActions>
      </Card>
    </Dialog>
  );
};

export default ConfirmationModal;
