// AuthProvider.js

import React, { createContext, useState, useEffect } from 'react';
import PropTypes from "prop-types";
import jwtDecode from 'jsonwebtoken';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    const token = localStorage.getItem("token");
    const isLocked = localStorage.getItem("isLocked");
    if (token) {
      const decodedToken = jwtDecode.decode(token);
      if (decodedToken?.exp * 1000 > Date.now()) {
        // Token is still valid
        setUser(decodedToken);
        if (isLocked == "true") navigate("/locked");
      } else {
        // Token has expired, log the user out
        setUser(null);
        localStorage.removeItem("token");
        navigate("/authentication/sign-in");
      }
    } else {
      navigate("/authentication/sign-in");
    }
    setLoading(false);
  }, []);

  const login = (token) => {
    localStorage.setItem("token", token);
    localStorage.setItem("isLocked", false);
    const decodedToken = jwtDecode.decode(token);
    setUser(decodedToken);
  };

  const logout = () => {
    localStorage.removeItem("token");
    setUser(null);
    navigate("/authentication/sign-in");
  };

  if (loading)
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          minHeight: "100vh",
        }}
      >
        <p>Loading...</p>
      </div>
    );

  return <AuthContext.Provider value={{ user, login, logout }}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
    children: PropTypes.any,
};

export default AuthProvider;
