import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import ConfigureCamera from "./components/ConfigureCamera";
import ConfigureWorkplace from "./components/ConfigureWorkplace";
import ConfigureUser from "./components/ConfigureUser";
import ArgonTypography from "components/ArgonTypography";
import { toast } from "react-toastify";
import { isString } from "utils";
import configs from "config/base-url";
import axios from "axios";
import { useEffect, useState } from "react";

function Configuration() {
  const [workplaces, setWorkplaces] = useState([]);
  const [cameras, setCameras] = useState([]);
  const [users, setUsers] = useState([]);

  const fetchWorkplace = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const resp = await axios.get(configs.baseURL + "workplace/get-all-workplace", { headers });

      const { data } = resp;
      if (data.success) {
        setWorkplaces(data.response);
      } else {
        toast.error("Can't fetch workplace data!");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Can't fetch workplace data!");
    }
  };

  const fetchCamera = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const resp = await axios.get(configs.baseURL + "camera/get-all-camera", { headers });

      const { data } = resp;
      if (data.success) {
        setCameras(data.response);
      } else {
        toast.error("Can't fetch camera data!");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Can't fetch camera data!");
    }
  };

  const fetchUsers = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const resp = await axios.get(configs.baseURL + "users/get-all-users-of-manager", { headers });

      const { data } = resp;
      if (data.success) {
        setUsers(data.response);
      } else {
        toast.error("Can't fetch user data!");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Can't fetch user data");
    }
  };

  useEffect(() => {
    fetchWorkplace();
    fetchCamera();
    fetchUsers();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox display="flex" alignItems="center" justifyContent="space-between" mt={5}>
        <ArgonBox display="flex" flexDirection="column" sx={{ zIndex: 10 }}>
          <ArgonTypography style={{ color: "white" }} variant="h4">
            Configuration
          </ArgonTypography>
          <ArgonTypography style={{ color: "white" }} variant="body2">
            You can configure everything here
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox py={3} mt={2}>
        <ArgonBox mb={3}>
          <Grid container spacing={3} sx={{ alignItems: "stretch" }}>
            <Grid item xs={12} sm={6} md={4}>
              <ConfigureWorkplace workplaces={workplaces} refetch={fetchWorkplace} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ConfigureCamera cameras={cameras} workplaces={workplaces} refetch={fetchCamera} />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <ConfigureUser users={users} refetch={fetchUsers} workplaces={workplaces} />
            </Grid>
          </Grid>
        </ArgonBox>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default Configuration;
