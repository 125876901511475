import ArgonSelect from 'components/ArgonSelect'
import React from 'react'
import configs from "config/base-url";

export default function WireClassSelect(props) {
    const { onChange, value, name, ...rest } = props

    return (
        <ArgonSelect
            options={configs.CaseOptions}
            {...rest}
            value={configs.CaseOptions.find(option => option.value == value)}
            onChange={(e) => onChange({ target: { value: e.value, name } })}
        />
    )
}
