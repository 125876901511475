
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faL, faTrash, faUser } from "@fortawesome/free-solid-svg-icons";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Data
import DataTable from "examples/Tables/DataTable";
import { useEffect, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, Link } from "@mui/material";
import ArgonSelect from "components/ArgonSelect";
import configs from "config/base-url";
import axios from "axios";
import ArgonInput from "components/ArgonInput";
import { toast } from "react-toastify";
import { isString } from "utils";
import ConfirmationModal from "components/ConfrimationModal";
import Prof from "assets/images/prof.svg";

const initialState = {
  id: "",
  email: "",
  first_name: "",
  last_name: "",
  password: "",
  company_name: "",
  badge_no: "",
  workplace_ids: [],
};

function ConfigureUser({ users, refetch, workplaces }) {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setState(initialState);
  };

  const [state, setState] = useState(initialState);

  const [tableDataState, setTableDataState] = useState({
    columns: [
      { Header: "Badge_NO", accessor: "badge_no" },
      { Header: "Cam_Password", accessor: "camera_access_password", width: "100%" },
      { Header: "Action", accessor: "action" },
    ],

    rows: [],
  });

  const [workplaceList, setWorkplaceList] = useState([]);

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    message: "",
    data: "",
  });

  useEffect(() => {
    setTableDataState({
      ...tableDataState,
      rows: users.map((obj, index) => ({
        ...obj,
        action: (
          <ArgonBox display="flex" alignItems="center" sx={{ gap: 2 }}>
            <FontAwesomeIcon
              icon={faEdit}
              color="black"
              fontSize={"1rem"}
              onClick={() => handleEdit(obj)}
            />
            <FontAwesomeIcon
              icon={faTrash}
              color="black"
              fontSize={"1rem"}
              onClick={() => {
                setDeleteConfirmation({
                  open: true,
                  data: obj.id,
                  message: `Are you sure you want to delete ${obj.badge_no}`,
                });
              }}
            />
          </ArgonBox>
        ),
      })),
    });
  }, [users]);

  useEffect(() => {
    setWorkplaceList(
      workplaces.map((obj) => ({
        value: obj.id,
        label: obj.workplace_name,
      }))
    );
  }, [workplaces]);

  const handleEdit = async (obj) => {
    const {
      id,
      first_name,
      last_name,
      company_name,
      badge_no,
      camera_access_password,
      workplaces,
    } = obj;
    setState({
      id,
      first_name,
      last_name,
      company_name,
      badge_no,
      workplace_ids: workplaces.map(({ id }) => id),
      password: camera_access_password,
    });

    handleOpen();
  };

  const handleForm = (evt) => {
    if (Array.isArray(evt)) {
      setState({
        ...state,
        workplace_ids: evt.map((ele) => ele.value),
      });
    } else {
      const value = evt.target.value;
      setState({
        ...state,
        [evt.target.name]: value,
      });
    }
  };

  const [loadingMutation, setLoadingMutation] = useState(false);

  const handleSubmit = async () => {
    setLoadingMutation(true);
    try {
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };
      const { id, ...userData } = state;
      const resp = await axios.post(configs.baseURL + "users/add-user", userData, { headers });

      const { data } = resp;
      if (data.success) {
        refetch();
        toast.success("New user created!");
        handleClose();
      } else {
        toast.error("Can't save data!, try again");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    } finally {
      setLoadingMutation(false);
    }
  };

  const handleEditSubmit = async () => {
    setLoadingMutation(true);
    try {
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };
      const { id, password, ...bodyData } = state;
      const resp = await axios.put(
        configs.baseURL + "users/edit-profile-of-staff-by-manager/" + state.id,
        { ...bodyData, camera_access_password: password },
        { headers }
      );

      const { data } = resp;
      if (data.success) {
        refetch();
        toast.success("User data updated");
        handleClose();
      } else {
        toast.error("Can't save data!, try again");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    } finally {
      setLoadingMutation(false);
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };

      const resp = await axios.delete(
        configs.baseURL + "users/delete-profile-of-staff-by-manager/" + deleteConfirmation.data,
        {
          headers,
        }
      );

      const { data } = resp;
      if (data.success) {
        setDeleteConfirmation({ open: false, message: "", data: "" });
        refetch();
        toast.success("User deleted!");
      } else {
        toast.error("Can't delete user, try again");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    }
  };

  return (
    <Card sx={{ overflow: "visible", flex: 1 }}>
      <ArgonBox display="flex" justifyContent="center" alignItems="center" pt={2} px={2}>
        <ArgonTypography variant="h6">User Configuration</ArgonTypography>
      </ArgonBox>
      <ArgonBox
        display="flex"
        justifyContent="center"
        alignItems="flex-start"
        height={60}
        pt={1}
        px={2}
      >
        <p style={{ fontSize: "15px", textAlign: "center" }}>
          You can add new user or edit other user details
        </p>
      </ArgonBox>
      <ArgonBox p={2} mt={3}>
        <Grid container alignItems="center" justifyContent={"center"}>
          <Grid item xs={12} textAlign={"center"}>
            <img src={Prof} height={150} width={150} />
            {/* <FontAwesomeIcon icon={faUser} color="black" fontSize={"6rem"} /> */}
          </Grid>
        </Grid>
      </ArgonBox>
      <ArgonBox
        pt={4}
        pb={2}
        px={2}
        display="flex"
        justifyContent="center"
        flexDirection={{ xs: "column", sm: "row" }}
        mt="auto"
      >
        <ArgonButton color="primary" onClick={handleOpen}>
          Add
        </ArgonButton>
      </ArgonBox>
      <ArgonBox py={1}>
        <DataTable
          table={tableDataState}
          entriesPerPage={false}
          showTotalEntries={false}
          isSorted={false}
          noEndBorder
        />
      </ArgonBox>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth
      >
        <Card sx={{ p: 2 }}>
          <DialogTitle>{state.id ? "Edit" : "Add"} User</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <ArgonBox p={2}>
              <ArgonBox>
                <ArgonBox>
                  <ArgonBox mt={1.625}>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <ArgonBox
                          display="flex"
                          flexDirection="column"
                          justifyContent="flex-end"
                          height="100%"
                        >
                          <ArgonTypography variant="caption" mb={1}>
                            Workplaces
                          </ArgonTypography>
                          <ArgonSelect
                            name="workplace_ids"
                            defaultValue={workplaceList?.filter((obj) =>
                              state.workplace_ids?.includes(obj.value)
                            )}
                            placeholder="Workplaces"
                            options={workplaceList}
                            onChange={handleForm}
                            isClearable
                            isSearchable
                            isMulti
                          />
                        </ArgonBox>
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <ArgonTypography variant="caption" mb={1}>
                          First Name
                        </ArgonTypography>
                        <ArgonInput
                          label="First Name"
                          type="text"
                          value={state.first_name}
                          placeholder="First Name"
                          name="first_name"
                          onChange={handleForm}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ArgonTypography variant="caption" mb={1}>
                          Last Name
                        </ArgonTypography>
                        <ArgonInput
                          label="Last Name"
                          type="text"
                          value={state.last_name}
                          placeholder="Last Name"
                          name="last_name"
                          onChange={handleForm}
                        />
                      </Grid>
                      {!state.id && (
                        <Grid item xs={12} md={6}>
                          <ArgonTypography variant="caption" mb={1}>
                            Email
                          </ArgonTypography>
                          <ArgonInput
                            label="Email"
                            type="text"
                            value={state.email}
                            placeholder="Email"
                            name="email"
                            onChange={handleForm}
                          />
                        </Grid>
                      )}

                      <Grid item xs={12} md={6}>
                        <ArgonTypography variant="caption" mb={1}>
                          Company Name
                        </ArgonTypography>
                        <ArgonInput
                          label="Company Name"
                          type="text"
                          value={state.company_name}
                          placeholder="Company Name"
                          name="company_name"
                          onChange={handleForm}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ArgonTypography variant="caption" mb={1}>
                          Badge NO
                        </ArgonTypography>
                        <ArgonInput
                          label="Badge NO"
                          type="text"
                          value={state.badge_no}
                          placeholder="Badge NO"
                          name="badge_no"
                          onChange={handleForm}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <ArgonTypography variant="caption" mb={1}>
                          {state.id ? "Camera Password" : "Password"}
                        </ArgonTypography>
                        <ArgonInput
                          label="Password"
                          type="password"
                          value={state.password}
                          placeholder="Password"
                          name="password"
                          onChange={handleForm}
                          autoComplete="off"
                        />
                      </Grid>
                    </Grid>
                  </ArgonBox>
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonButton
              sx={{ mr: 1 }}
              color="warning"
              variant="text"
              onClick={() => {
                setState(initialState);
                handleClose();
              }}
            >
              Cancel
            </ArgonButton>
            {state?.id ? (
              <ArgonBox>
                <Link onClick={handleEditSubmit}>
                  <ArgonButton disabled={loadingMutation} color="primary" fullWidth>
                    Update
                  </ArgonButton>
                </Link>
              </ArgonBox>
            ) : (
              <ArgonBox>
                <Link onClick={handleSubmit}>
                  <ArgonButton disabled={loadingMutation} color="primary" fullWidth>
                    Submit
                  </ArgonButton>
                </Link>
              </ArgonBox>
            )}
          </DialogActions>
        </Card>
      </Dialog>
      <ConfirmationModal
        open={deleteConfirmation.open}
        onClose={() => {
          setDeleteConfirmation({ open: false, data: "", message: "" });
        }}
        onConfirm={handleDeleteSubmit}
        message={deleteConfirmation.message}
      />
    </Card>
  );
}

export default ConfigureUser;
