// WebSocketProvider.js

import React, { createContext, useContext, useEffect, useState } from 'react';
import PropTypes from "prop-types";
import configs from "config/base-url";


const connect = () => {
    try {
        const wsURL = configs.STREAMING_URL || "ws://localhost:8000/rf/ws";
        const websocket = new WebSocket(wsURL);
        return websocket
    } catch (error) {
        console.log(error)
        return connect()
    }
};

const initialValues = {
    websocket: connect(),
    connect: () => { },
    close: () => { },
}

const WebSocketContext = createContext(initialValues);

const WebSocketProvider = ({ children }) => {
    const [websocket, setWebsocket] = useState(initialValues.websocket)
    const close = () => {
        websocket?.close()
        setWebsocket()
    };

    const reconnect = () => {
        setWebsocket(connect())
    }


    return <WebSocketContext.Provider value={{ websocket, reconnect, close }}>{children}</WebSocketContext.Provider>;
};

WebSocketProvider.propTypes = {
    children: PropTypes.any,
};

export default WebSocketProvider;

export const useWebSocket = (onMessageCB = () => { }) => {
    const context = useContext(WebSocketContext)
    const { websocket } = context;

    useEffect(() => {
        if (websocket && onMessageCB) {
            websocket.onmessage = async (event) => {
                if (typeof onMessageCB === 'function') {
                    onMessageCB(event.data)
                }
            };
        }
    }, [websocket])

    return context;
}
