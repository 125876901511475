require('dotenv').config()

const configs = {
    baseURL : process.env.REACT_APP_BASE_URL,
    STREAMING_URL : process.env.REACT_APP_STREAMING_URL,
    PREDICTION_AI_URL : process.env.REACT_APP_PREDICTION_AI_URL,
    CaseOptions : [
        { value: 0, label: `Case 0 : OK, welding is conform to quality standards`, color: "#2DCE89" },
        { value: 1, label: `Case 1 : NOK, Bent strands longer than 1.5 mm`, color: "red" },
        { value: 4, label: `Case 4 : NOK, Over-welded weld nugget`, color: '#5E72E4' },
        { value: 6, label: `Case 6 : NOK, Unwelded strands`, color: "#172B4D" },
        { value: 8, label: `Case 8 : NOK, Looped strands`, color: "#11CDEF" },
    ]
}

export default configs;