import React, { useRef, useEffect, useMemo } from 'react'

export default function ImageIndicator({ image, bbox, class_id }) {
    const imgRef = useRef(null);
    const boxRef = useRef(null);

    const src = useMemo(() => {
        if (image instanceof Blob) {
            return URL.createObjectURL(image)
        }

        if (image?.type == 'Buffer') {
            return `data:image/${Buffer.from(image.data).toString("base64").charAt(0) == "/"
                ? "jpg"
                : "png"
                };base64,${Buffer.from(image.data).toString("base64")}`
        }
        return image
    }, [image])

    useEffect(() => {
        try {
            if (!imgRef.current || !boxRef.current || !bbox || !image) {
                return;
            }

            const originalImage = new Image();
            originalImage.src = imgRef.current.src;
            originalImage.onload = () => {
                const [x_min, y_min, x_max, y_max] = bbox || [0, 0, 0, 0];

                const box = boxRef.current;

                const boxWidth = x_max - x_min;
                const boxHeight = y_max - y_min;

                const width = (boxWidth / originalImage.width) * 100;
                const height = (boxHeight / originalImage.height) * 100;

                const left = (x_min / originalImage.width) * 100;
                const top = (y_min / originalImage.height) * 100;

                box.style.width = `${width}%`;
                box.style.height = `${height}%`;
                box.style.display = "block";
                box.style.border = `6px solid ${class_id == 0 ? "#1AAE6F" : "red"}`;
                box.style.left = `${left}%`;
                box.style.top = `${top}%`;
            };
        } catch (error) {
            console.log(error);
        }
    }, [bbox, image, class_id]);

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
                overflow: 'hidden'
            }}
        >
            <img
                ref={imgRef}
                src={src}
                alt="Uploaded"
                style={{
                    position: "absolute",
                    width: "100%",
                    height: "100%",
                    top: 0,
                    left: 0,
                }}
            />
            <div
                ref={boxRef}
                style={{
                    position: "absolute",
                    display: "none",
                }}
            />
        </div>
    )
}
