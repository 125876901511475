

import { useContext, useEffect, useState } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonButton from "components/ArgonButton";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";

// Data
import dataTableData from "layouts/ecommerce/orders/order-list/data/dataTableData";
import { AuthContext } from "providers/AuthProvider";
import axios from "axios";
import configs from "config/base-url";
import IdCell from "./components/IdCell";
import DefaultCell from "./components/DefaultCell";
import RangePicker from "components/RangePicker";
import StatusCell from "./components/StatusCell";
import {
  Box,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
} from "@mui/material";
import ArgonAvatar from "components/ArgonAvatar";
import CustomerCell from "./components/CustomerCell";
import dayjs from "dayjs";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DeleteIcon from "@mui/icons-material/Delete";
import PersonIcon from "@mui/icons-material/Person";
import editIcon from "assets/images/icons/Edit.svg";
import { toast } from "react-toastify";
import ArgonInput from "components/ArgonInput";
import ArgonSelect from "components/ArgonSelect";
import { TimeField } from "@mui/x-date-pickers";
import { isString } from "utils";
import ConfirmationModal from "components/ConfrimationModal";
import imgPlaceholder from "assets/images/img-placeholder.png";
import ArgonDatePicker from "components/ArgonDatePicker";
import WireClassSelect from "components/WireClassSelect";
import ImageIndicator from "components/ImageIndicator";

function OrderList() {
  const { user } = useContext(AuthContext);
  const [menu, setMenu] = useState(null);
  const [openEditBox, setOpenEditBox] = useState(false);
  const [openViewBox, setOpenViewBox] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState({
    open: false,
    message: "",
    data: "",
  });
  const [tableDataState, setTableDataState] = useState({
    columns: [
      {
        Header: "Image Scanned",
        accessor: "id",
        Cell: ({ value, row }) => {
          return (
            <ArgonBox display="flex" alignItems="center">
              <Checkbox defaultChecked={false} />
              <CustomerCell
                name={value.slice(0, 10).toUpperCase()}
                image={
                  row.original?.image &&
                  `data:image/${Buffer.from(row.original?.image).toString("base64").charAt(0) == "/"
                    ? "jpg"
                    : "png"
                  };base64,${Buffer.from(row.original?.image).toString("base64")}`
                }
              />
            </ArgonBox>
          );
        },
      },
      {
        Header: "Date & Time",
        accessor: "createdAt",
        Cell: ({ value }) => <DefaultCell value={dayjs(value).format("DD.MM.YYYY HH:mm")} />,
      },
      {
        Header: "IP Camera",
        accessor: "camera",
        Cell: ({ value, row }) => <DefaultCell value={row.original?.camera_id != "0" ? `${row.original.camera.camera_ip_address}:${row.original.camera.port}` : "image upload"} />,
      },
      {
        Header: "Employee",
        accessor: "user",
        Cell: ({ value }) => <DefaultCell value={value?.badge_no} />,
      },
      {
        Header: "Workplace ID",
        accessor: "camera.workplace",
        Cell: ({ value, row }) => <DefaultCell value={row.original?.camera_id != "0" ? `${row.original.camera.workplace_id.slice(0, 10)}` : "NA"} />,
      },
      {
        Header: "STATUS",
        accessor: "status",
        Cell: ({ value, row }) => (
          <ArgonBox
            sx={{
              bgcolor: row?.original?.wire_class == '0' ? "#B0EED3" : "#FDD1DA",
              color: row?.original?.wire_class == '0' ? "#1AAE6F" : "#F80031",
              fontWeight: "bold",
              paddingX: 1,
              borderRadius: 1,
              fontSize: 12,
            }}
          >
            {row?.original?.wire_class == '0' ? "OK/PASS" : "NOT OK"}
          </ArgonBox>
        ),
      },
      {
        Header: "ACTIONS",
        Cell: ({ row }) => (
          <ArgonBox display="flex" alignItems="center" sx={{ gap: 2 }}>
            <ArgonBox onClick={() => handleViewClick(row)}>
              <Icon fontSize="small" color="secondary">
                <VisibilityIcon />
              </Icon>
            </ArgonBox>
            {
              user?.role == "manager" && (
                <>
                  <ArgonBox onClick={() => handleEditClick(row)}>
                    <img style={{ width: 50 }} src={editIcon} />
                  </ArgonBox>
                  <ArgonBox
                    onClick={() => {
                      setDeleteConfirmation({
                        open: true,
                        data: row.original.id,
                        message: `Are you sure you want to delete this history`,
                      });
                    }}
                  >
                    <Icon fontSize="small" color="secondary">
                      <DeleteIcon />
                    </Icon>
                  </ArgonBox>
                </>
              )
            }
          </ArgonBox>
        ),
      },
    ],

    rows: [],
  });

  const [editData, setEditData] = useState({
    id: "",
    status: "",
    accuracy: "",
    wire_class: "",
    coordinates: {},
  });
  const [loadingEdit, setLoadingEdit] = useState(false);

  const handleEditClick = (row) => {
    const { original } = row;
    setEditData({
      id: original.id,
      status: original.status,
      accuracy: original.accuracy,
      wire_class: original.wire_class,
      coordinates: original.coordinates,
    });
    setOpenEditBox(true);
  };

  const handleEditForm = (evt) => {
    if (evt.target?.checked) {
      setEditData((prvs) => ({
        ...prvs,
        [evt.target.name]: evt.target.value == "true",
      }));
    } else if (evt.target.name.split(".").length > 1) {
      const field = evt.target.name.split(".");
      setEditData((prvs) => ({
        ...prvs,
        coordinates: {
          ...prvs.coordinates,
          [field[1]]: evt.target.value,
        },
      }));
    } else {
      setEditData((prvs) => ({
        ...prvs,
        [evt.target.name]: evt.target.value,
      }));
    }
  };

  const submitEditForm = async () => {
    setLoadingEdit(true);
    try {
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };
      const { id, ...newData } = editData;
      const resp = await axios.put(
        configs.baseURL + "wire-insight/edit-wireinsight/" + id,
        newData,
        {
          headers,
        }
      );

      const { data } = resp;
      if (data.success) {
        fetchHistory();
        toast.success("History data updated");
        setOpenEditBox(false);
      } else {
        toast.error("Can't save data!, try again");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    } finally {
      setLoadingEdit(false);
    }
  };

  const [viewData, setViewData] = useState({
    id: "",
  });

  const handleViewClick = ({ original }) => {
    setViewData(original);
    setOpenViewBox(true);
  };

  const [dateRange, setDateRange] = useState({ from: "", to: "" });
  const [timeRange, setTimeRange] = useState({ from: "", to: "" });
  const [workplaces, setWorkplaces] = useState([]);
  const [singleDay, setSingleDay] = useState("");

  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);
  const [workplaceList, setWorkplaceList] = useState([]);

  useEffect(() => {
    fetchHistory();
    fetchWorkplace();
  }, []);

  const fetchWorkplace = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const resp = await axios.get(configs.baseURL + "workplace/get-all-workplace", { headers });

      const { data } = resp;
      if (data.success) {
        setWorkplaceList(
          data.response.map((obj) => ({
            value: obj.id,
            label: obj.workplace_name,
          }))
        );
      } else {
        // Handle login error
        console.error("Login failed");
      }
    } catch (error) {
      console.error("Login failed with error:", error);
    }
  };

  const fetchHistory = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };

      let dRange = { ...dateRange };
      if (singleDay) {
        dRange.from = new Date(dayjs(singleDay).startOf("day"));
        dRange.to = new Date(dayjs(singleDay).endOf("day"));
      }

      const resp = await axios.get(configs.baseURL + "wire-insight/get-all-wireinsight", {
        headers,
        params: {
          dateRange: dRange,
          timeRange,
          workplaces,
        },
      });

      const { data } = resp;
      if (data.success) {
        console.log("data.response ::::::::::", data.response);
        setTableDataState({
          ...tableDataState,
          rows: data.response,
        });
      } else {
        // Handle login error
        console.error("Login failed");
      }
    } catch (error) {
      console.error("Login failed with error:", error);
    }
  };

  const downloadCSV = () => {
    if (tableDataState.rows.length > 0) {
      const head = [
        { name: "ID" },
        {
          name: "DATE",
          key: "createdAt",
          transform: (data) => dayjs(data).format("DD.MM.YYYY HH:mm"),
        },
        { name: "CAMERA IP", key: "camera", transform: (data) => data?.camera_ip_address },
        { name: "EMPLOYEE", key: "user", transform: (data) => data?.badge_no },
        { name: "WORKPLACE ID", key: "camera", transform: (data) => data?.workplace?.id },
        { name: "STATUS" },
      ];
      // Convert tableData to a CSV string
      const csvContent =
        "data:text/csv;charset=utf-8," +
        tableDataState.rows
          .map((row, index) => {
            const values = head.map(({ name, key, transform }) => {
              const col = row[key || name.toLowerCase()];
              const nCol = transform?.(col);
              return nCol || col;
            });

            if (index == 0) {
              return head.map((hd) => hd.name) + "\n" + values;
            } else {
              return values;
            }
          })
          .join("\n");

      // Create a data URI for the CSV content
      const encodedUri = encodeURI(csvContent);

      // Create an invisible link element to trigger the download
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "table_data.csv");

      // Simulate a click to trigger the download
      link.click();
      toast.success("Table data exported successfully");
    }
  };

  const handleDeleteSubmit = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };

      const resp = await axios.delete(
        configs.baseURL + "wire-insight/delete-wireinsight/" + deleteConfirmation.data,
        {
          headers,
        }
      );

      const { data } = resp;
      if (data.success) {
        setDeleteConfirmation({ open: false, message: "", data: "" });
        fetchHistory();
        toast.success("Wireinsight history deleted!");
      } else {
        toast.error("Can't delete this history, try again");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Something went wrong, try again");
    }
  };

  useEffect(() => {
    if (dateRange.to || dateRange.from) setSingleDay("");
  }, [dateRange]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox
        display="flex"
        alignItems={{ md: "center" }}
        justifyContent="space-between"
        flexDirection={{ md: "row", xs: "column" }}
        sx={{ gap: 2 }}
        mt={5}
      >
        <ArgonBox display="flex" flexDirection="column" sx={{ zIndex: 10 }}>
          <ArgonTypography style={{ color: "white" }} variant="h4">
            History
          </ArgonTypography>
          <ArgonTypography style={{ color: "white" }} variant="body2">
            All cases scanned by your facility in one place
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox my={3}>
        <Card>
          <ArgonBox
            display="flex"
            alignSelf={{ xs: "center", sm: "flex-end" }}
            sx={{ gap: 4, m: 4 }}
          >
            <ArgonButton
              variant="contained"
              sx={{
                paddingLeft: 5,
                paddingRight: 5,
                bgcolor: "#5e72e4",
                paddingLeft: 5,
                paddingRight: 5,
                color: "#fff",
                "&:hover": {
                  bgcolor: "#5e72e4",
                  color: "#fff",
                },
                "&:focus:not(:hover)": {
                  bgcolor: "#5e72e4",
                  color: "#fff",
                },
              }}
              onClick={openMenu}
            >
              Filter
            </ArgonButton>
            <ArgonBox ml={1}>
              {user?.role == "manager" && (
                <ArgonButton
                  variant="outlined"
                  color="white"
                  sx={{
                    paddingLeft: 5,
                    paddingRight: 5,
                    bgcolor: "white",
                    borderColor: "#5e72e4",
                    color: "#5e72e4",
                    "&:hover": {
                      borderColor: "#5e72e4",
                    },
                  }}
                  onClick={downloadCSV}
                >
                  <Icon>description</Icon>
                  &nbsp;Export CSV
                </ArgonButton>
              )}
            </ArgonBox>
          </ArgonBox>
          {tableDataState.rows.length > 0 && (
            <DataTable table={tableDataState} entriesPerPage={false} />
          )}
        </Card>
      </ArgonBox>

      {/* filter modal */}
      <Dialog
        close={() => {
          setMenu(false);
        }}
        open={menu}
      >
        <Card sx={{ p: 2 }}>
          <DialogTitle id="alert-dialog-title">Filter History</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              width="100%"
              mt={2}
            >
              <ArgonTypography variant="caption" mb={1}>
                Pick Date Range
              </ArgonTypography>
              <RangePicker value={dateRange} onChange={setDateRange} />
            </ArgonBox>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              width="100%"
              mt={2}
            >
              <ArgonTypography variant="caption" mb={1}>
                Pick a day
              </ArgonTypography>
              <ArgonDatePicker
                input={{ placeholder: "YYYY-MM-DD" }}
                value={singleDay}
                onChange={(cValue) => {
                  setDateRange({ from: "", to: "" });
                  setSingleDay(cValue);
                }}
              />
            </ArgonBox>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              height="100%"
              mt={2}
              width={410}
            >
              <ArgonTypography variant="caption" mb={1}>
                Workplaces
              </ArgonTypography>
              <ArgonSelect
                name="workplace_ids"
                placeholder="Workplaces"
                options={workplaceList}
                defaultValue={workplaceList?.filter((obj) => workplaces?.includes(obj.value))}
                onChange={(val) => {
                  setWorkplaces(val.map((el) => el.value));
                }}
                isClearable
                isSearchable
                isMulti
              />
            </ArgonBox>
            <ArgonBox
              display="flex"
              flexDirection="column"
              justifyContent="flex-end"
              width="100%"
              mt={2}
            >
              <ArgonTypography variant="caption" mb={1}>
                Pick Time Range
              </ArgonTypography>
              <ArgonBox display="flex" gap={2}>
                <ArgonBox display="flex" flexDirection="column">
                  <TimeField
                    InputProps={{ placeholder: "HH : MM", sx: { paddingLeft: 0 } }}
                    format="HH:mm"
                    value={dayjs(timeRange.from)}
                    onChange={(nVal) =>
                      setTimeRange((prvs) => ({
                        ...prvs,
                        from: dayjs(nVal).startOf("minute") || "",
                      }))
                    }
                  />
                </ArgonBox>
                <ArgonTypography>-</ArgonTypography>
                <ArgonBox display="flex" flexDirection="column">
                  <TimeField
                    minTime={dayjs(timeRange.from)}
                    InputProps={{ placeholder: "HH : MM", sx: { paddingLeft: 0 } }}
                    format="HH:mm"
                    value={dayjs(timeRange.to)}
                    onChange={(nVal) =>
                      setTimeRange((prvs) => ({ ...prvs, to: dayjs(nVal).endOf("minute") || "" }))
                    }
                  />
                </ArgonBox>
              </ArgonBox>
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonButton
              sx={{ mr: 1 }}
              color="warning"
              variant="text"
              onClick={() => {
                setMenu(false);
              }}
            >
              Cancel
            </ArgonButton>

            <ArgonBox>
              <ArgonButton
                color="primary"
                fullWidth
                onClick={() => {
                  fetchHistory();
                  setMenu(false);
                }}
              >
                Filter
              </ArgonButton>
            </ArgonBox>
          </DialogActions>
        </Card>
      </Dialog>

      {/* edit dialog  */}
      <Dialog open={openEditBox} onClose={() => { }}>
        <Card sx={{ p: 2 }}>
          <DialogTitle id="alert-dialog-title">{"Edit History"}</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <ArgonBox mt={1.625}>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <ArgonTypography variant="caption" mb={1}>
                    Status
                  </ArgonTypography>
                  <RadioGroup
                    sx={{ display: "flex", ml: 1, flexDirection: "row", gap: 1 }}
                    name="status"
                    value={editData.status}
                    onChange={handleEditForm}
                  >
                    <FormControlLabel value={true} control={<Radio />} label="OK" />
                    <FormControlLabel value={false} control={<Radio />} label="NOT Ok" />
                  </RadioGroup>
                </Grid>
                <Grid item xs={12} md={6}>
                  <ArgonTypography variant="caption" mb={1}>
                    Accuracy
                  </ArgonTypography>
                  <ArgonInput
                    label="Accuracy"
                    value={editData.accuracy}
                    type="text"
                    placeholder="Accuracy"
                    name="accuracy"
                    onChange={handleEditForm}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  <ArgonTypography variant="caption" mb={1}>
                    Wire Class
                  </ArgonTypography>
                  <WireClassSelect
                    label="Wire Class"
                    placeholder="Wire Class"
                    name="wire_class"
                    value={editData.wire_class}
                    onChange={handleEditForm}
                  />
                </Grid>
                <Grid item xs={12} md={6}></Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="body" mb={1}>
                    Coordinates
                  </ArgonTypography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    X min
                  </ArgonTypography>

                  <ArgonInput
                    label="X min"
                    type="text"
                    placeholder="X min"
                    name="coordinates.x_min"
                    value={editData.coordinates?.x_min}
                    onChange={handleEditForm}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    Y min
                  </ArgonTypography>

                  <ArgonInput
                    label="Y min"
                    type="text"
                    placeholder="Y min"
                    name="coordinates.y_min"
                    value={editData.coordinates?.y_min}
                    onChange={handleEditForm}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    X max
                  </ArgonTypography>

                  <ArgonInput
                    label="X max"
                    type="text"
                    placeholder="X max"
                    name="coordinates.x_max"
                    value={editData.coordinates?.x_max}
                    onChange={handleEditForm}
                  />
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    Y max
                  </ArgonTypography>

                  <ArgonInput
                    label="Y max"
                    type="text"
                    placeholder="Y max"
                    name="coordinates.y_max"
                    value={editData.coordinates?.y_max}
                    onChange={handleEditForm}
                  />
                </Grid>
              </Grid>
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonButton
              sx={{ mr: 1 }}
              color="warning"
              variant="text"
              disabled={loadingEdit}
              onClick={() => {
                setOpenEditBox(false);
              }}
            >
              Cancel
            </ArgonButton>

            <ArgonBox>
              <ArgonButton
                onClick={submitEditForm}
                disabled={loadingEdit}
                color="primary"
                fullWidth
              >
                Submit
              </ArgonButton>
            </ArgonBox>
          </DialogActions>
        </Card>
      </Dialog>

      {/* view dialog  */}
      <Dialog
        open={openViewBox}
        onClose={() => {
          setOpenViewBox(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <Card sx={{ p: 2 }}>
          <DialogTitle id="alert-dialog-title">{viewData?.id}</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <ArgonBox mt={1.625}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ArgonBox
                    sx={{
                      width: "-webkit-fill-available",
                      borderRadius: 10,
                      backgroundColor: "#000",
                      height: '400px'
                    }}>
                    <ImageIndicator
                      class_id={viewData?.wire_class}
                      image={viewData?.image || imgPlaceholder}
                      bbox={viewData.coordinates ? [viewData.coordinates?.x_min || 0, viewData.coordinates?.y_min || 0, viewData.coordinates?.x_max || 0, viewData.coordinates?.y_max || 0] : undefined}
                    />
                  </ArgonBox>
                </Grid>
                <Grid item xs={6}>
                  <ArgonTypography variant="caption" mb={1}>
                    Accuracy
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonTypography variant="body">{viewData.accuracy}%</ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={6}>
                  <ArgonTypography variant="caption" mb={1}>
                    Status
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonBox
                      sx={{
                        bgcolor: viewData.wire_class == '0' ? "#B0EED3" : "#FDD1DA",
                        color: viewData.wire_class == '0' ? "#1AAE6F" : "#F80031",
                        fontWeight: "bold",
                        paddingX: 1,
                        borderRadius: 1,
                        fontSize: 12,
                      }}
                    >
                      {viewData.wire_class == '0' ? "OK/PASS" : "NOT OK"}
                    </ArgonBox>
                  </ArgonBox>
                </Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="body">Wire class</ArgonTypography>
                </Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="body"> {viewData.wire_class && configs.CaseOptions.find(val => viewData.wire_class == val.value).label} </ArgonTypography>
                </Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="body">Coordinates</ArgonTypography>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    X min
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonTypography variant="body">
                      {viewData.coordinates?.x_min ?? "-"}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    Y min
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonTypography variant="body">
                      {viewData.coordinates?.y_min ?? "-"}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    X max
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonTypography variant="body">
                      {viewData.coordinates?.x_max ?? "-"}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
                <Grid item xs={6} sm={3}>
                  <ArgonTypography variant="caption" mb={1}>
                    Y max
                  </ArgonTypography>
                  <ArgonBox display="flex" sx={{ gap: 1 }}>
                    <ArgonTypography variant="body">
                      {viewData.coordinates?.y_max ?? "-"}
                    </ArgonTypography>
                  </ArgonBox>
                </Grid>
              </Grid>
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonBox>
              <ArgonButton
                color="primary"
                fullWidth
                onClick={() => {
                  setOpenViewBox(false);
                }}
              >
                Close
              </ArgonButton>
            </ArgonBox>
          </DialogActions>
        </Card>
      </Dialog>

      <ConfirmationModal
        open={deleteConfirmation.open}
        onClose={() => {
          setDeleteConfirmation({ open: false, data: "", message: "" });
        }}
        onConfirm={handleDeleteSubmit}
        message={deleteConfirmation.message}
      />
    </DashboardLayout>
  );
}

export default OrderList;