import Overview from "layouts/ecommerce/overview";
import ProductPage from "layouts/ecommerce/products/product-page";
import OrderList from "layouts/ecommerce/orders/order-list";
import SignInCover from "layouts/authentication/sign-in/cover";
import Configuration from "layouts/ecommerce/configuration";
import Support from "layouts/ecommerce/support";
import Locked from "layouts/authentication/locked";
import Block from "assets/images/icons/block.svg";
import ArgonBox from "components/ArgonBox";
import { Navigate } from "react-router-dom";
import ResetPass from "layouts/authentication/ResetPass";

const SvgIcon = ({ svg }) => (
  <ArgonBox component="img" src={svg} alt="caution" width="15px" height="15px" />
);

const routes = [
  {
    name: "Cover",
    key: "cover",
    route: "/authentication/sign-in",
    component: <SignInCover />,
    noCollapse: true,
  },
  {
    type: "collapse",
    // name: "wireinsight",
    name: "Inspection",
    key: "wireinsight",
    route: "/wireinsight",
    component: <ProductPage />,
    noCollapse: true,
    icon: <SvgIcon svg={Block} />,
  },
  {
    type: "collapse",
    name: "History",
    key: "history",
    route: "/history",
    component: <OrderList />,
    noCollapse: true,
    icon: <SvgIcon svg={Block} />,
  },
  {
    type: "collapse",
    name: "Lock",
    key: "lock",
    onClick: () => {
      localStorage.setItem("isLocked", true);
      window.location.replace("/locked");
    },
    route: "/locked",
    component: <Locked />,
    noCollapse: true,
  },
  {
    name: "Reset Password",
    key: "resetpass",
    route: "/reset-password",
    component: <ResetPass />,
    noCollapse: true,
  },
];

export const managerRoutes = [
  {
    name: "Cover",
    key: "cover",
    route: "/authentication/sign-in",
    component: <SignInCover />,
    noCollapse: true,
  },
  {
    type: "collapse",
    // name: "Wireinsight",
    name: "Inspection",
    key: "wireinsight",
    route: "/wireinsight",
    component: <ProductPage />,
    noCollapse: true,
    icon: <SvgIcon svg={Block} />,
  },
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    component: <Overview />,
    noCollapse: true,
    icon: <SvgIcon svg={Block} />,
  },
  {
    type: "collapse",
    name: "History",
    key: "history",
    route: "/history",
    component: <OrderList />,
    noCollapse: true,
    icon: <SvgIcon svg={Block} />,
  },
  {
    type: "collapse",
    name: "Configuration",
    key: "configuration",
    route: "/configuration",
    component: <Configuration />,
    noCollapse: true,
    icon: <SvgIcon svg={Block} />,
  },
  {
    type: "collapse",
    name: "Support",
    key: "support",
    route: "/support",
    component: <Support />,
    noCollapse: true,
    icon: <SvgIcon svg={Block} />,
  },
  {
    name: "Reset Password",
    key: "resetpass",
    route: "/reset-password",
    component: <ResetPass />,
    noCollapse: true,
  },
];

export default routes;
