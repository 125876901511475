import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { Box, Card, Paper } from "@mui/material";
import ArgonTypography from "components/ArgonTypography";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonInput from "components/ArgonInput";
import shareIcon from "assets/images/icons/share.svg";
import amazit from "assets/images/amazit.png";
import ArgonButton from "components/ArgonButton";
import { useEffect, useState } from "react";
import configs from "config/base-url";
import { toast } from "react-toastify";
import axios from "axios";

const CHAT = [
];

function Configuration() {
  const [chat, setChat] = useState(CHAT);
  const [chatInput, setChatInput] = useState("");

  // const sendChat = () => {
  //   if (chatInput.trim()) {
  //     setChat((prvs) => [...prvs, { name: "manager", isManager: true, message: chatInput }]);
  //     setChatInput("");
  //   }
  // };

  useEffect(() => {
    fetchChats()
  }, [])

  const fetchChats = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const resp = await axios.get(configs.baseURL + "users/get-all-chats", { headers });

      const { data } = resp;
      if (data.success) {
        const neChatData = data.response.map(chatD => ( { name: "manager", isManager: true, message: chatD.chat } ) )
        setChat(neChatData);
        setChatInput("");
      } else {
        toast.error("Can't fetch chats data!");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Can't fetch chats data!");
    }
  };

  const handleSubmit = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };
      const resp = await axios.post(configs.baseURL + "users/create-chat", { chat: chatInput }, { headers });

      const { data } = resp;
      if (data.success) {
        fetchChats();
      } else {
        toast.error("Can't save data, try again");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox
        display="flex"
        alignItems={{ md: "center" }}
        justifyContent="space-between"
        flexDirection={{ md: "row", xs: "column" }}
        sx={{ gap: 2 }}
        mt={5}
      >
        <ArgonBox display="flex" flexDirection="column" sx={{ zIndex: 10 }}>
          <ArgonTypography style={{ color: "white" }} variant="h4">
            Support
          </ArgonTypography>
          <ArgonTypography style={{ color: "white" }} variant="body2">
            We&apos;re one click away to help you with everything wireinsight!
          </ArgonTypography>
        </ArgonBox>
      </ArgonBox>
      <ArgonBox my={3}>
        <Card sx={{ height: "75vh" }}>
          <Grid
            container
            spacing={2}
            height="100%"
            marginTop={1}
            marginLeft="0px"
            marginRight="0px"
            width="100%"
          >
            <Grid xs={12} height="100%">
              <ArgonBox
                display="flex"
                flexDirection="column"
                sx={{ height: "100%", borderRadius: 4 }}
              >
                <Box
                  elevation={2}
                  sx={{
                    paddingX: 2,
                    paddingY: 3,
                    borderRadius: 3,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 1,
                  }}
                >
                  <ArgonBox
                    // bgColor="dark"
                    sx={{
                      borderRadius: 2,
                      // height: "100%",
                      width: 50,
                      display: "Flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {/* <ArgonAvatar size="xs" bgColor="dark" /> */}
                    <ArgonBox
                      component="img"
                      src={amazit}
                      alt="caution"
                      width="100%"
                      height="100%"
                    />
                  </ArgonBox>
                  <ArgonBox sx={{ display: "flex", flexDirection: "column" }}>
                    <ArgonTypography variant="subtitle2" sx={{ margin: 0 }}>
                    {"Support team"}
                    </ArgonTypography>
                    <ArgonTypography variant="caption" sx={{ margin: 0 }}>
                    {"+971 588350586"}
                    </ArgonTypography>
                    <ArgonTypography variant="caption" sx={{ margin: 0 }}>
                    {"contact@amazit.dev"}
                    </ArgonTypography>
                  </ArgonBox>
                </Box>
                <ArgonBox sx={{ flex: 1, height: "100%", overflowY: "auto" }}>
                  <ArgonBox display="flex" flexDirection="column" sx={{ padding: 2 }}>
                    {chat.map((msg, ind) => (
                      <Paper
                        key={ind}
                        sx={{
                          alignSelf: msg.isManager ? "flex-end" : "flex-start",
                          paddingY: 1,
                          paddingX: 2,
                          marginY: 0.5,
                          borderTopLeftRadius: 10,
                          borderBottomLeftRadius: !msg.isManager ? 0 : 10,
                          borderTopRightRadius: 10,
                          borderBottomRightRadius: msg.isManager ? 0 : 10,
                          backgroundColor: msg.isManager ? "#ebebeb" : "#FFF",
                        }}
                      >
                        <ArgonTypography variant="body2">{msg.message}</ArgonTypography>
                      </Paper>
                    ))}
                  </ArgonBox>
                </ArgonBox>
                <Paper
                  sx={{ display: "flex", alignItems: "center", gap: 1, paddingY: 3, paddingX: 2 }}
                >
                  <ArgonInput
                    value={chatInput}
                    onChange={(e) => {
                      setChatInput(e.target.value);
                    }}
                    onKeyDown={e => e.key === 'Enter' && handleSubmit()} 
                    autoFocus
                    rows={1}
                  ></ArgonInput>
                  <ArgonButton
                    variant="contained"
                    sx={{
                      bgcolor: "#5e72e4",
                      color: "#fff",
                      "&:hover": {
                        bgcolor: "#5e72e4",
                        color: "#fff",
                      },
                      "&:focus:not(:hover)": {
                        bgcolor: "#5e72e4",
                        color: "#fff",
                      },
                    }}
                    onClick={handleSubmit}
                  >
                    <ArgonBox
                      component="img"
                      src={shareIcon}
                      alt="caution"
                      width="15px"
                      height="15px"
                    />
                  </ArgonButton>
                </Paper>
              </ArgonBox>
            </Grid>
          </Grid>
        </Card>
      </ArgonBox>
    </DashboardLayout>
  );
}

export default Configuration;
