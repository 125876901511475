import { Box, Modal } from "@mui/material";
import ArgonDatePicker from "components/ArgonDatePicker";
import ArgonTypography from "components/ArgonTypography";
import dayjs from "dayjs";
import PropTypes from "prop-types";

const RangePicker = ({ value, onChange }) => {
  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
        <Box>
          <ArgonDatePicker
            input={{ placeholder: "YYYY-MM-DD" }}
            value={value.from}
            onChange={(cValue) => {
              onChange({ ...value, from: new Date(dayjs(cValue).startOf("day")) });
            }}
          />
        </Box>
        <ArgonTypography>-</ArgonTypography>
        <Box>
          <ArgonDatePicker
            options={{ minDate: new Date(value?.from) }}
            input={{ placeholder: "YYYY-MM-DD" }}
            value={value.to}
            onChange={(cValue) => {
              onChange({ ...value, to: new Date(dayjs(cValue).endOf("day")) });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

RangePicker.defaultProps = {
  keepMounted: false,
  open: false,
  value: {
    from: "",
    to: "",
  },
};

// Typechecking props for the RangePicker
RangePicker.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
  value: PropTypes.objectOf({
    from: PropTypes.string,
    to: PropTypes.string,
  }),
  onChange: PropTypes.func,
  keepMounted: PropTypes.bool,
};

export default RangePicker;
