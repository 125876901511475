import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";

// Argon Dashboard 2 PRO MUI example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

import ProductInfo from "layouts/ecommerce/products/product-page/components/ProductInfo";

import ImageUploader from "./components/ImageUploader";
import ArgonProgress from "components/ArgonProgress";
import { useContext, useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWarning } from "@fortawesome/free-solid-svg-icons";
import ArgonButton from "components/ArgonButton";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import ArgonInput from "components/ArgonInput";
import { isString } from "utils";
import { toast } from "react-toastify";
import axios from "axios";
import configs from "config/base-url";
import { AuthContext } from "providers/AuthProvider";
import ArgonAvatar from "components/ArgonAvatar";
import ArgonSelect from "components/ArgonSelect";
import cautionIcon from "assets/images/icons/caution.svg";
import { useWebSocket } from "providers/WebSocketProvider";


const issueTypes = [
  { value: "camera", label: "Camera Issue" },
  { value: "detection", label: "Detection Issue" },
  { value: "other", label: "Other" },
];

function ProductPage() {

  const timeInSec = 1.5;
  const timerRef = useRef(null)
  const [image, setImage] = useState(null);
  const [imageUploading, setImageUploading] = useState(false);
  const [cameraListData, setCameraListData] = useState(null);
  const [aiResponse, setAiResponse] = useState(null);
  const [showTimer, setShowTimer] = useState(false);
  const [isStartTimer, setIsStartTimer] = useState(false);
  const [noAIPopup, setNoAIPopup] = useState(false);
  const [timeLeft, setTimeLeft] = useState(timeInSec);
  const [openTroubleShootDialog, setOpenTroubleShootDialog] = useState(false);
  const [troubleshootIssue, setTroubleshootIssue] = useState({
    issue_type: "",
    comments: "",
  });
  const [sendingTrouble, setSendingTrouble] = useState(false);
  const [selectedCam, setSelectedCam] = useState(null);
  const { user } = useContext(AuthContext);

  const startStreaming = (frameData) => {
    if (frameData) {
      const blob = new Blob([frameData], { type: "image/jpeg" });
      setImage(blob)
    }
  }

  const { close, reconnect, websocket } = useWebSocket(startStreaming)

  useEffect(() => {
    if (showTimer && isStartTimer) {
      timerRef.current = setInterval(() => {
        if (timeLeft > 0) {
          setTimeLeft(timeLeft - 0.1 > 0 ? timeLeft - 0.1 : 0);
        } else {
          handleNext();
        }
      }, 100);
      return () => {
        clearTimeout(timerRef.current)
      }
    }
  }, [showTimer, timeLeft, timerRef, isStartTimer])

  const submitWireInsight = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      };

      const bodyFormData = new FormData();
      bodyFormData.append('myFile', image)
      bodyFormData.append('camera_id', selectedCam ? selectedCam?.id : "0")
      bodyFormData.append('status', aiResponse?.status == 1 ? true : false)
      bodyFormData.append('accuracy', aiResponse?.conf)
      bodyFormData.append('coordinates', JSON.stringify({
        x_min: aiResponse?.bbox[0],
        y_min: aiResponse?.bbox[1],
        x_max: aiResponse?.bbox[2],
        y_max: aiResponse?.bbox[3],
      }))
      bodyFormData.append('wire_class', aiResponse?.class_id)

      const resp = await axios.post(
        configs.baseURL + "wire-insight/add-wireinsight",
        bodyFormData,
        { headers }
      );

      const { data } = resp;
      if (data.success) {
        toast.success("Inference submitted successfully");
        setTroubleshootIssue({ issue_type: "", commnets: "" });
        setOpenTroubleShootDialog(false);
      } else {
        toast.error("Can't send inference!, try again");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    }
  };

  const sendtoTroubleshoot = async (e) => {
    e.preventDefault();
    try {
      setSendingTrouble(true);

      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      };

      const bodyFormData = new FormData();
      bodyFormData.append('myFile', image)
      bodyFormData.append('issue_type', troubleshootIssue?.issue_type)
      bodyFormData.append('comments', troubleshootIssue?.comments)
      bodyFormData.append('camera_id', selectedCam ? selectedCam?.id : "0")
      bodyFormData.append('status', aiResponse?.status == 1 ? true : false)
      bodyFormData.append('accuracy', aiResponse?.conf)
      bodyFormData.append('coordinates', JSON.stringify({
        x_min: aiResponse?.bbox[0],
        y_min: aiResponse?.bbox[1],
        x_max: aiResponse?.bbox[2],
        y_max: aiResponse?.bbox[3],
      }))
      bodyFormData.append('wire_class', aiResponse?.class_id)

      const resp = await axios.post(
        configs.baseURL + "troubleshoot/add-troubleshoot",
        bodyFormData,
        { headers }
      );

      const { data } = resp;

      if (data.success) {
        toast.success("Issue send successfully");
        handleClose(true);
      } else {
        toast.error("Can't send issue!, try again");
      }
    } catch (error) {
      console.log(error);
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    } finally {
      setSendingTrouble(false);
    }
  };

  const sendtoTroubleshootWithoutAI = async (e) => {
    e.preventDefault();
    try {
      close
      setSendingTrouble(true);

      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "multipart/form-data",
      };

      const bodyFormData = new FormData();
      bodyFormData.append('myFile', image)
      bodyFormData.append('issue_type', troubleshootIssue?.issue_type)
      bodyFormData.append('comments', troubleshootIssue?.comments)
      bodyFormData.append('camera_id', selectedCam ? selectedCam?.id : "0")
      bodyFormData.append('status', false)
      bodyFormData.append('accuracy', 0)
      bodyFormData.append('coordinates', JSON.stringify({
        x_min: 0,
        y_min: 0,
        x_max: 0,
        y_max: 0,
      }))
      bodyFormData.append('wire_class', aiResponse?.class_id)

      const resp = await axios.post(
        configs.baseURL + "troubleshoot/add-troubleshoot",
        bodyFormData,
        { headers }
      );

      const { data } = resp;

      if (data.success) {
        toast.success("Issue send successfully");
        handleClose(true);
      } else {
        toast.error("Can't send issue!, try again");
      }
    } catch (error) {
      console.log(error);
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    } finally {
      setSendingTrouble(false);
    }
  };

  const openTroubleshootWithoutAI = () => {
    setNoAIPopup(true)
    handleTroubleSoot()
  }

  const resetStates = () => {
    setIsStartTimer(false);
    clearTimeout(timerRef.current);
    setTimeLeft(timeInSec);
    setShowTimer(false);
    setImage(null);
    setAiResponse(null);
    setNoAIPopup(false);
    reconnect()
  };

  const reStartTimer = () => {
    if (isStartTimer) {
      setTimeLeft(timeInSec);
    }
    else {
      startTimer()
    }
  };

  const startTimer = () => {
    setIsStartTimer(true);
    setShowTimer(true);
  };

  const stopTimer = () => {
    setIsStartTimer(false);
    clearTimeout(timerRef.current);
  };

  const handleStop = () => {
    resetStates();
  };

  const handleNext = () => {
    resetStates();
    submitWireInsight();
  };

  const handleTroubleSoot = () => {
    stopTimer();
    setOpenTroubleShootDialog(true);
  };

  const handleClose = (reset = false) => {
    setTroubleshootIssue({ issue_type: "", comments: "" });
    setOpenTroubleShootDialog(false);
    if (reset) {
      resetStates();
    } else {
      if (!noAIPopup) {
        startTimer();
      }
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ArgonBox display="flex" flexDirection="column" sx={{ zIndex: 10,position:'relative' }}>
          <ArgonTypography style={{ color: "white" }} variant="h4">
          Wireinsight
          </ArgonTypography>
          <ArgonTypography style={{ color: "white" }} variant="body2">
          This is where the magic happens.
          </ArgonTypography>
        </ArgonBox>
      <ArgonBox py={3}>
        <Card
          sx={{ overflow: "visible" }}
          style={{ backgroundColor: "transparent", boxShadow: "none" }}
        >
          <ArgonBox>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6} lg={7} order={1}>
                <ImageUploader
                  selectedCam={selectedCam}
                  setSelectedCam={setSelectedCam}
                  setCameraListData={setCameraListData}
                  imageUploading={imageUploading}
                  setImageUploading={setImageUploading}
                  image={image}
                  isStreaming={!!websocket}
                  response={aiResponse}
                  setImage={setImage}
                  stopStreaming={close}
                  setAiResponse={(data) => {
                    setAiResponse(data);
                    if (data) {
                      reStartTimer();
                    } else {
                      resetStates();
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={5} order={2}>
                <Card sx={{ padding: 3, flex: 1 }}>
                  <ProductInfo
                    cameraListData={cameraListData}
                    aiResponse={aiResponse}
                    camera={selectedCam}
                    websocket={websocket}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={7} order={{ xs: 4, md: 3 }}>
                <Card sx={{ padding: 2 }} style={{ backgroundColor: "transparent" }}>
                  <ArgonTypography variant="h5" sx={{ marginBottom: 1 }}>
                    {user?.role == "staff" ? "Employee" : "Manager"} Info
                  </ArgonTypography>
                  <ArgonBox display="flex" alignItems="center" justifyContent="space-between">
                    <ArgonBox display="flex" alignItems="center" gap={1}>
                      <ArgonAvatar bgColor="dark" size="sm" />
                      <ArgonTypography variant="subtitle2">
                        {user?.first_name} {user?.last_name}{" "}
                        {user?.company_name && `(${user.company_name})`}
                      </ArgonTypography>
                    </ArgonBox>
                    <ArgonBox>
                      <ArgonButton
                        variant="contained"
                        sx={{
                          paddingLeft: 5,
                          paddingRight: 5,
                          bgcolor: "#5e72e4",
                          paddingLeft: 5,
                          paddingRight: 5,
                          color: "#fff",
                          "&:hover": {
                            bgcolor: "#5e72e4",
                            color: "#fff",
                          },
                          "&:focus:not(:hover)": {
                            bgcolor: "#5e72e4",
                            color: "#fff",
                          },
                        }}
                      >
                        {user?.badge_no}
                      </ArgonButton>
                    </ArgonBox>
                  </ArgonBox>
                </Card>
              </Grid>
              <Grid item xs={12} md={6} lg={5} order={{ xs: 3, md: 4 }}>
                <ArgonBox sx={{ margin: 1 }}>
                  {showTimer && (
                    <div>
                      <ArgonBox
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginBottom: 1 }}
                      >
                        <ArgonTypography variant="button" fontWeight="bold" color="text">
                          Moving to next frame
                        </ArgonTypography>
                        <ArgonTypography
                          variant="button"
                          fontWeight="medium"
                          color="text"
                          textTransform="capitalize"
                        >
                          {parseInt(timeLeft + 1)} sec left
                        </ArgonTypography>
                      </ArgonBox>
                      <ArgonProgress
                        color="dark"
                        value={(timeLeft / timeInSec) * 100}
                        sx={{ height: "8px" }}
                      />
                      <div
                        style={{
                          marginTop: "1rem",
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-around",
                          alignItems: "center",
                        }}
                      >
                        <ArgonBox onClick={handleTroubleSoot}>
                          <ArgonBox
                            component="img"
                            src={cautionIcon}
                            alt="caution"
                            width="50px"
                            height="50px"
                            sx={{ marginTop: "17px" }}
                          />
                        </ArgonBox>
                        <ArgonButton
                          sx={{ mr: 1, paddingX: 4, borderColor: "#000" }}
                          color="warning"
                          variant="outlined"
                          onClick={handleStop}
                        >
                          STOP
                        </ArgonButton>
                        <ArgonButton
                          variant="gradient"
                          sx={{
                            mr: 1, backgroundColor: "#344767", '&:hover, &:focus': {
                              backgroundColor: "#344767 !important"
                            }, paddingX: 4, color: "#FFF"
                          }}
                          //   disabled={sendingWireinsight}
                          // onClick={submitWireInsight}
                          onClick={handleNext}
                        >
                          &#x2713; NEXT
                        </ArgonButton>
                      </div>
                    </div>
                  )}
                </ArgonBox>
              </Grid>
            </Grid>
            <ArgonBox
              sx={{
                display: "flex",
                marginY: 10,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ArgonBox
                component="img"
                src={cautionIcon}
                alt="caution"
                width="25px"
                height="25px"
                sx={{ marginTop: "0px" }}
                onClick={openTroubleshootWithoutAI}
              />
              <ArgonTypography variant="body2">
                {" "}
                If you have any issues, please click here and we’ll follow up with your supervisor
              </ArgonTypography>
            </ArgonBox>
          </ArgonBox>
        </Card>
      </ArgonBox>
      {/* troubleshoot dialog  */}
      <Dialog open={openTroubleShootDialog} onClose={() => handleClose()}>
        <Card sx={{ p: 2 }}>
          <DialogTitle id="alert-dialog-title">Troubleshoot</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <ArgonBox mt={1.625}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <ArgonTypography variant="caption" mb={1}>
                    Issue Type
                  </ArgonTypography>
                  <ArgonSelect
                    name="issue_type"
                    label="Issue Type"
                    options={issueTypes}
                    value={issueTypes.find((item) => item.value === troubleshootIssue.issue_type)}
                    placeholder="Issue Type"
                    onChange={(e) => {
                      setTroubleshootIssue((prvs) => ({ ...prvs, issue_type: e.value || "" }));
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <ArgonTypography variant="caption" mb={1}>
                    Comments
                  </ArgonTypography>
                  <ArgonInput
                    label="Comments"
                    type="text"
                    placeholder="Comments"
                    name="comments"
                    value={troubleshootIssue.comments}
                    onChange={(e) => {
                      setTroubleshootIssue((prvs) => ({ ...prvs, comments: e.target.value }));
                    }}
                    multiline
                    rows={3}
                  />
                </Grid>
              </Grid>
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonButton
              sx={{ mr: 1 }}
              color="warning"
              variant="text"
              disabled={sendingTrouble}
              onClick={(e) => {
                handleClose();
              }}
            >
              Cancel
            </ArgonButton>
            <ArgonBox>
              <ArgonButton
                color="primary"
                fullWidth
                disabled={
                  !troubleshootIssue?.issue_type?.trim() ||
                  !troubleshootIssue?.comments?.trim() ||
                  sendingTrouble
                }
                onClick={(e) => noAIPopup ? sendtoTroubleshootWithoutAI(e) : sendtoTroubleshoot(e)}
              >
                Submit
              </ArgonButton>
            </ArgonBox>
          </DialogActions>
        </Card>
      </Dialog>
    </DashboardLayout>
  );
}

export default ProductPage;
