import { useContext, useState } from "react";

// react-router-dom components
import { Link } from "react-router-dom";
import axios from "axios";
import { useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";

// Argon Dashboard 2 PRO MUI components
import ArgonBox from "components/ArgonBox";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import ArgonButton from "components/ArgonButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import Socials from "layouts/authentication/components/Socials";
import Separator from "layouts/authentication/components/Separator";
import configs from "config/base-url";
import { AuthContext } from "providers/AuthProvider";

// Images
import bgImage from "assets/images/wire-bg.jpg";
import textImg from "assets/images/Logo.svg";

import { toast } from "react-toastify";
import { isString } from "utils";

function Cover() {
  const { login } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState({
    badge_no: "",
    password: "",
  });

  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    badge_no: "",
    password: "",
  });
  const [rememberMe, setRememberMe] = useState(true);

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleLoginForm = (evt) => {
    setError({
      ...error,
      [evt.target.name]: "",
    });
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  };

  const handleLogin = async () => {
    if (!state.badge_no && !state.badge_no.trim().length) {
      setError((prvs) => ({ ...prvs, badge_no: "This field is required" }));
      return;
    } else if (!state.password && !state.password.trim().length) {
      setError((prvs) => ({ ...prvs, password: "This field is required" }));
      return;
    }

    try {
      setLoading(true);
      const headers = {
        "Content-Type": "application/json",
      };
      const resp = await axios.post(configs.baseURL + "users/login", state, headers);

      const { data } = resp;
      if (data.success) {
        login(data.response);

        navigate("/wireinsight");
      } else {
        toast.error("Can't login to you're account, try again");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    } finally {
      setLoading(false);
    }
  };

  return (
    <CoverLayout title={<img style={{maxWidth:'250px', height:"auto"}} src={textImg} alt="logo"/> } description="" image={bgImage}>
      <Card>
        <ArgonBox pt={3} px={3}>
          <ArgonTypography variant="h3" color="dark" fontWeight="bold">
            Welcome
          </ArgonTypography>
          <ArgonTypography variant="body2" color="text">
            Enter your details below to sign in
          </ArgonTypography>
        </ArgonBox>
        <ArgonBox p={3}>
          <ArgonBox component="form" role="form">
            <ArgonBox mb={3}>
              <ArgonTypography
                display="block"
                variant="caption"
                fontWeight="bold"
                color="dark"
                sx={{ ml: 0.5, mb: 1 }}
              >
                Username
              </ArgonTypography>
              <ArgonInput
                type="text"
                value={state.badge_no}
                placeholder="Username"
                name="badge_no"
                onChange={handleLoginForm}
              />

              {error.badge_no && (
                <ArgonTypography variant="caption" color="warning" sx={{ ml: 0.5, mt: 0, p: 0 }}>
                  {error.badge_no}
                </ArgonTypography>
              )}
            </ArgonBox>
            <ArgonBox mb={3}>
              <ArgonTypography
                display="block"
                variant="caption"
                fontWeight="bold"
                color="dark"
                sx={{ ml: 0.5, mb: 1 }}
              >
                Password
              </ArgonTypography>
              <ArgonInput
                type="password"
                value={state.password}
                placeholder="Password"
                name="password"
                onChange={handleLoginForm}
              />
              {error.password && (
                <ArgonTypography variant="caption" color="warning" sx={{ ml: 0.5, mt: 0, mb: 0 }}>
                  {error.password}
                </ArgonTypography>
              )}
            </ArgonBox>

            <ArgonBox mt={4}>
              <Link onClick={handleLogin}>
                <ArgonButton color="primary" fullWidth>
                  Login
                </ArgonButton>
              </Link>
            </ArgonBox>
          </ArgonBox>
        </ArgonBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
