import React, { useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog, faUpload } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { AuthContext } from "providers/AuthProvider";
import {
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Radio,
  DialogActions,
  Box,
  Icon,
} from "@mui/material";
import ArgonBox from "components/ArgonBox";
import ArgonButton from "components/ArgonButton";
import { Link } from "react-router-dom";
import axios from "axios";
import configs from "config/base-url";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { isString } from "utils";
import ArgonTypography from "components/ArgonTypography";
import ArgonInput from "components/ArgonInput";
import SettingsIcon from "@mui/icons-material/Settings";
import ImageIndicator from "components/ImageIndicator";

const ImageUploader = ({ isStreaming, image, setImage, selectedCam, imageUploading, setImageUploading, setSelectedCam, setAiResponse, response, setCameraListData, stopStreaming }) => {
  const [open, setOpen] = useState(false);

  const [value, setValue] = useState();
  const [successMessage, setSuccessMessage] = useState("Image uploaded successfully");
  const [cameraList, setCameraList] = useState(null);
  const [isStaticUpload, setIsStaticUpload] = useState(false);
  const [isCameraAuth, setIsCameraAuth] = useState(false);
  const [cameraPass, setCameraPass] = useState({ value: "", error: "" });
  const [loadingSetting, setLoadingSetting] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [falseStatusError, setFalseStatusError] = useState("");

  const { user } = useContext(AuthContext);
  const inputRef = useRef();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setValue(selectedCam);
    setOpen(false);
  };

  const handleChange = (event) => {
    setValue(cameraList.find((value) => value.id == event.target.value));
  };

  const handlePrediction = async () => {
    await stopStreaming();
    await handleImageUploadForStreaming();
  };

  const handleImageUpload = (e) => {
    stopStreaming()
    toast.promise(async () => await imageUpload(e), {
      pending: "Image is uploading...",
      success: successMessage,
      error: {
        render({ data }) {
          return data || "Can't upload image!, try again";
        },
      },
    });
  };

  const imageUpload = async (e) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      setImageUploading(true);
      setIsStaticUpload(true);
      try {
        const formData = new FormData();
        formData.append("myFile", selectedImage);

        setImage(selectedImage);
        const resp = await axios.post(configs.PREDICTION_AI_URL, formData);

        const { data } = resp;
        if (data.length > 0) {
          if (!data[0].status || data[0].status == 0) {
            setAiResponse(null);
            setFalseStatusError(data[0].message)
            throw "Can't upload image";
          } else {
            setAiResponse(data[0]);
          }
        } else {
          setAiResponse(null);
          throw "Can't upload image!, try again";
        }
      } catch (error) {
        setAiResponse(null);
        const message = error?.response?.data?.response;
        throw isString(message) ? message : "Can't upload image";
      } finally {
        e.target.value = "";
        setImageUploading(false);
      }
    }
  };

  const handleImageUploadForStreaming = (e) => {
    toast.promise(async () => await imageUploadForStream(), {
      pending: "Image is uploading...",
      success: successMessage,
      error: {
        render({ data }) {
          return data || "Can't upload image!, try again";
        },
      },
    });
  };

  const imageUploadForStream = async () => {
    if (image) {
      setImageUploading(true);

      try {
        const formData = new FormData();
        formData.append("myFile", image);

        const resp = await axios.post(configs.PREDICTION_AI_URL, formData);

        const { data } = resp;
        if (data.length > 0) {
          if (!data[0].status || data[0].status == 0) {
            setAiResponse(null);
            setFalseStatusError(data[0].message)
            throw "Can't upload image";
          } else {
            setAiResponse(data[0]);
          }
        } else {
          setAiResponse(null);
          throw "Can't upload image!, try again";
        }
      } catch (error) {
        setAiResponse(null);
        const message = error?.response?.data?.response;
        throw isString(message) ? message : "Can't upload image";
      } finally {
        setImageUploading(false);
      }
    }
  };

  useEffect(() => {
    if (user?.role == "manager") fetchCamera();
  }, [user]);

  const fetchCamera = async () => {
    try {
      const headers = {
        token: localStorage.getItem("token"),
      };
      const resp = await axios.get(configs.baseURL + "camera/get-all-camera", { headers });

      const { data } = resp;
      if (data.success) {
        setCameraList(data.response);
        setCameraListData(data.response);
      } else {
        toast.error("Can't fetch camera data!");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message || "Can't fetch camera data!");
    }
  };

  const authenticateCameraPass = async () => {
    if (!cameraPass.value && !cameraPass.value.trim().length) {
      setCameraPass((prvs) => ({ ...prvs, error: "This field is required" }));
      return;
    }

    try {
      setLoadingSetting(true);
      const headers = {
        token: localStorage.getItem("token"),
        "Content-Type": "application/json",
      };
      const resp = await axios.post(
        configs.baseURL + "users/authenticate-camera",
        { password: cameraPass.value },
        { headers }
      );

      const { data } = resp;
      if (data.success) {
        toast.success("Camera authenticated");
        setIsCameraAuth(true);
        setCameraList(data.response);
      } else {
        toast.error("Can't authenticate camera, check your password");
      }
    } catch (error) {
      const message = error?.response?.data?.response;
      toast.error(isString(message) ? message : "Something went wrong, try again");
    } finally {
      setLoadingSetting(false);
    }
  };

  const handleSettingSubmit = async () => {
    if (!isCameraAuth && user?.role !== "manager") {
      await authenticateCameraPass();
    } else {
      setSelectedCam(value);
      setOpen(false);
    }
  };

  const handleCloseValidate = () => {
    setAiResponse(null);
    setImage(null);
    setFalseStatusError("");
  };

  useEffect(() => {
    let timeout;
    if (open && errorMsg.length) {
      timeout = setTimeout(() => {
        setErrorMsg("");
      }, 3000);
    }

    return () => {
      if (timeout) clearTimeout(timeout);
    };
  }, [open]);

  return (
    <div>
      <div
        style={{
          position: "relative",
          width: "100%",
          maxWidth:'970px',
          height: "530px",
          background: "#000",
          borderRadius: 43,
          overflow: "hidden",
        }}
      >
        {image && <ImageIndicator image={image} {...response} />
        }
        {isStreaming && <ArgonButton
          variant="contained"
          sx={{
            position: "absolute",
            bottom: 16,
            right: 26,
            paddingLeft: 5,
            paddingRight: 5,
            bgcolor: "#B0EED3",
            paddingLeft: 5,
            paddingRight: 5,
            zIndex: `11 !important`,
            visibility: isStaticUpload ? "hidden" : "visible",
            color: "#1AAE6F",
            "&:hover": {
              bgcolor: "#B0EED3",
              color: "#1AAE6F",
            },
            "&:focus:not(:hover)": {
              bgcolor: "#B0EED3",
              color: "#1AAE6F",
            },
          }}
          onClick={() => handlePrediction()}
        >
          Run Prediction
        </ArgonButton>}
        <div
          style={{
            position: "absolute",
            top: "15px",
            right: "26px",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            zIndex: 10,
          }}
        >
          <FontAwesomeIcon icon={faCog} size="lg" color={image ? "#8392ab" : "white"} onClick={handleOpen} />
          {user?.role == "manager" && (
            <div style={{ marginLeft: "10px" }}>
              <input
                style={{
                  opacity: 0,
                  width: "50px",
                  height: "30px",
                  display: "none",
                  visibility: "hidden",
                }}
                type="file"
                accept="image/*"
                onChange={handleImageUpload}
                ref={inputRef}
                disabled={imageUploading}
              />
              <ArgonBox >
                <FontAwesomeIcon

                  icon={faUpload}
                  onClick={() => {
                    inputRef.current.click();
                  }}
                  size="lg"
                  color={image ? "#8392ab" : "white"}

                />
              </ArgonBox>
            </div>
          )}
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        fullWidth
      >
        <Card sx={{ p: 2 }}>
          <DialogTitle>
            {isCameraAuth || user?.role == "manager" ? "Camera List" : "Camera Password"}
          </DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <ArgonBox p={2}>
              {errorMsg && (
                <ArgonBox sx={{ marginBottom: 2 }}>
                  <ArgonTypography variant="body" color="warning">
                    {errorMsg}
                  </ArgonTypography>
                </ArgonBox>
              )}
              {!isCameraAuth && user?.role !== "manager" ? (
                <ArgonBox>
                  <ArgonInput
                    type="password"
                    value={cameraPass.value}
                    placeholder="Camera Password"
                    name="password"
                    onChange={(e) => {
                      setCameraPass({ error: "", value: e.target.value });
                    }}
                  />
                  {cameraPass.error && (
                    <ArgonTypography
                      variant="caption"
                      color="warning"
                      sx={{ ml: 0.5, mt: 0, mb: 0 }}
                    >
                      {cameraPass.error}
                    </ArgonTypography>
                  )}
                </ArgonBox>
              ) : (
                <ArgonBox>
                  <ArgonBox>
                    <ArgonBox>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={12}>
                          <FormControl>
                            <RadioGroup
                              aria-labelledby="demo-controlled-radio-buttons-group"
                              name="controlled-radio-buttons-group"
                              value={value?.id}
                              onChange={handleChange}
                            >
                              {cameraList &&
                                cameraList.length > 0 &&
                                cameraList.map((data, index) => (
                                  <FormControlLabel
                                    key={index}
                                    value={data.id}
                                    control={<Radio />}
                                    label={data.camera_ip_address}
                                  />
                                ))}
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                      </Grid>
                    </ArgonBox>
                  </ArgonBox>
                </ArgonBox>
              )}
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonButton
              sx={{ mr: 1 }}
              color="warning"
              variant="text"
              onClick={() => handleClose()}
              disabled={loadingSetting}
            >
              Cancel
            </ArgonButton>
            <ArgonBox>
              <ArgonButton
                color="primary"
                fullWidth
                onClick={handleSettingSubmit}
                disabled={
                  loadingSetting || ((isCameraAuth || user?.role == "manager") && !value?.id)
                }
              >
                Submit
              </ArgonButton>
            </ArgonBox>
          </DialogActions>
        </Card>
      </Dialog>
      {Boolean(falseStatusError) && <Dialog open={Boolean(falseStatusError)} onClose={handleCloseValidate}>
        <Card sx={{ p: 2 }}>
          <DialogTitle>Validate</DialogTitle>
          <DialogContent sx={{ paddingBottom: 0 }}>
            <ArgonBox sx={{ marginBottom: 2 }}>
              <ArgonTypography variant="body" color="#67748e">
                {falseStatusError}
              </ArgonTypography>
            </ArgonBox>
          </DialogContent>
          <DialogActions>
            <ArgonButton sx={{ mr: 1 }} color="primary" onClick={handleCloseValidate}>
              Ok
            </ArgonButton>
          </DialogActions>
        </Card>
      </Dialog>}
    </div>
  );
};

export default ImageUploader;
